const testnetId = '0x61'
const mainnetId = '0x38'
const mainnetUrl = 'https://bsc-dataseed.binance.org/'
const testnetUrl = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
const usdtAddrTestnet = '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd'
const usdtAddrMainnet = '0x55d398326f99059fF775485246999027B3197955'
const routerAddrTestnet = '0xD99D1c33F9fC3444f8101754aBC46c52416550D1'
const routerAddrMainnet = '0x10ed43c718714eb63d5aa57b78b54704e256024e'
const wbnbAddrTestnet = '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd'
const wbnbAddrMainnet = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
const swapAgentAddressTestnet = '0xae850Fa5f1b032A1007f831a50750f3dcDEaC8A7'
const swapAgentAddressMainnet = '0x3Ee1a7497A715344Af6E3B3A3F4Dd5Be74B83e92'
const zwtAddressTestnet = '0x2742AB8fceA57068b3D8280e4b7A9fD3dc344b7E'
const zwtAddressMainnet = '0xE722137ee68C0e4C7F451Af646D17Ef669b11255'
const serverUrl = 'https://payment.private-tunnel.com:3099/'
const isTest = false
module.exports = {
    testnetId,
    mainnetId,
    testnetUrl,
    mainnetUrl,
    usdtAddrTestnet,
    usdtAddrMainnet,
    routerAddrTestnet,
    routerAddrMainnet,
    wbnbAddrTestnet,
    wbnbAddrMainnet,
    swapAgentAddressTestnet,
    swapAgentAddressMainnet,
    zwtAddressTestnet,
    zwtAddressMainnet,
    serverUrl,
    isTest
};
