
const moment = require('moment');
function shortenWalletAddress(account) {
    if (!account) {
        return ''
    }
    return `${account.substr(0, 6)}...${account.substr(-4, 4)} `
}

function showToast(text, timeout = 3000, isMobile = false) {
    let toastBody = document.createElement("div");
    if (isMobile === true) {
        toastBody.classList.add('zm-toast');
    } else {
        toastBody.classList.add('z-toast');
    }

    toastBody.innerText = text
    document.body.append(toastBody)
    // const timeoutID = window.setTimeout(() => {
    //     document.body.removeChild(toastBody)
    //     window.clearTimeout(timeoutID)
    // }, timeout);
}
function toFixed(x) {
    let e
    if (Math.abs(x) < 1.0) {
        e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getCurrentYear() {
    return moment().year();
}

module.exports = {shortenWalletAddress, showToast, toFixed, sleep, getCurrentYear};