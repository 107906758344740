import React, {Component} from 'react';
import './App.css';
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import Download from "./Desktop/Download";

import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);


class App extends Component {

    render() {
        const path = window.location.pathname
        if (path.indexOf('download') >= 0) {
            if (window.innerWidth < 900) {
                return (<Download/>);
            } else {
                return (<Download/>);
            }
        }
        if (window.innerWidth < 900) {
            return (<Mobile/>);
        } else {
            return (<Desktop/>);
        }

    }
}

export default App;
