import React, {Component} from 'react';
import './Desktop.css';
import webLogoPng from '../img/web_logo.png';
import coinLogo from '../img/coinLogo.svg';
import soundOff from '../img/sound_off.svg';
import soundOn from '../img/sound_on.svg';
import bgSound from '../sounds/bg_sound.mp3'
import webLogoBgPng from '../img/web_logo_bg.png'
import zombieGamePlay from '../img/background/web_bg_gameplay.png'
import nftCards from '../img/nft_card.png'
import member1 from '../img/team/member1.png'
import member2 from '../img/team/member2.png'
import member3 from '../img/team/member3.png'
import member4 from '../img/team/member4.png'
import member5 from '../img/team/member5.png'
import member6 from '../img/team/member6.png'
import member7 from '../img/team/member7.png'
import member8 from '../img/team/member8.png'
import partner1 from '../img/partners/1TrustFi Logo - White-Yellow.png'
import partner2 from '../img/partners/binstarter.png'
import partner3 from '../img/partners/blockchain-army.png'
import partner4 from '../img/partners/cspdao.png'
import partner5 from '../img/partners/dreamboat.png'
import partner6 from '../img/partners/GFM.png'
import partner7 from '../img/partners/iCe logo.png'
import partner8 from '../img/partners/thelighthouse.png'
import partnerGateIo from '../img/partners/gateio.png'
import partnerIbcig from '../img/partners/ibcig.png'
import partnerCoinlab from '../img/partners/CoinLAB.png'
import partnerAlien from '../img/partners/alien.png'
import audition from '../img/audition/verchains.png'
import timelineDot from '../img/timelineDot.svg'
import unfinishedCheck from '../img/unfinished_check.svg'
import finishedCheck from '../img/finished_check.svg'
import footerBg from '../img/foot_bg.png'
import facebook from '../img/social/facebook.svg'
import telegram from '../img/social/telegram.svg'
import twitter from '../img/social/twitter.svg'
import youtube from '../img/social/youtube.svg'
import arrowDown from '../img/arrow_down.svg'
import walletIcon from '../img/wallet.svg'
import bgHand from '../img/background/web_bg_hand.png'
import bloodScratch from '../img/background/blood_scratch.png'
import bgCar from '../img/background/web_bg_car.png'
import bgGrunge from '../img/background/grunge_texture.png'
import roadmapBg from '../img/background/roadmap_bg.png'
import weaponDesk from '../img/weapon_desk.png'
import normalWeaponCase from '../img/weapons/normal_weapon_case.png'
import normalWeaponCaseOwned from '../img/weapons/normal_weapon_case_owned.png'
import eliteWeaponCase from '../img/weapons/elite_weapon_case.png'
import eliteWeaponCaseOwned from '../img/weapons/elite_weapon_case_owned.png'
import legendaryWeaponCase from '../img/weapons/legendary_weapon_case.png'
import legendaryWeaponCaseOwned from '../img/weapons/legendary_weapon_case_owned.png'
import normalArrow from '../img/weapons/normal_arrow.svg'
import eliteArrow from '../img/weapons/elite_arrow.svg'
import legendaryArrow from '../img/weapons/legendary_arrow.svg'
import normalCaseOpen1 from '../img/weapons/normal_case_open_1.png'
import normalCaseOpen2 from '../img/weapons/normal_case_open_2.png'
import normalCaseOpen3 from '../img/weapons/normal_case_open_3.png'
import eliteCaseOpen1 from '../img/weapons/elite_case_open_1.png'
import eliteCaseOpen2 from '../img/weapons/elite_case_open_2.png'
import eliteCaseOpen3 from '../img/weapons/elite_case_open_3.png'
import legendaryCaseOpen1 from '../img/weapons/legendary_case_open_1.png'
import legendaryCaseOpen2 from '../img/weapons/legendary_case_open_2.png'
import legendaryCaseOpen3 from '../img/weapons/legendary_case_open_3.png'
import addCoinToWalletIcon from '../img/add_coin_to_wallet.svg'
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {shortenWalletAddress, showToast, sleep, getCurrentYear} from '../utils/methods';
import Web3 from "web3"
import {
    isTest, mainnetUrl,
    routerAddrMainnet,
    routerAddrTestnet,
    serverUrl,
    wbnbAddrMainnet,
    wbnbAddrTestnet, testnetUrl,
    usdtAddrMainnet,
    usdtAddrTestnet,
    zwtAddressMainnet,
    zwtAddressTestnet
} from '../utils/constants'
import IERC20Metadata from '../abis/IERC20Metadata.json'
import IPancakeRouter from '../abis/IPancakeRouter02.json'
import BN from "bn.js";
import axios from "axios";
import importantIcon from "../img/important.svg";


ChartJS.register(ArcElement, Tooltip, Legend);

class Desktop extends Component {

    audio;

    options = {
        onHover: (evt, item, legend) => {
            if (item.length > 0) {
                this.updateDoughnutHighlight(item[0].index)
            } else {
                this.resetDoughnutHighlight()
            }
        },
        plugins: {
            tooltip: {enabled: false}
        }
    }

    constructor() {
        super();
        this.state = {
            sound: false,
            chartData: {
                datasets: [
                    {
                        label: '# of Votes',
                        data: [40, 31, 14, 7, 5, 3],
                        backgroundColor: [
                            'rgb(255,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)'
                        ],
                        borderColor: [
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)'
                        ],
                        borderWidth: 2,
                    }
                ],
            },
            doughnutHoverIndex: 0,
            connectedAccount: null,
            usdtBalance: 0,
            usdtBalanceDisplay: 0,
            usdtAllowance: null,
            zwtBalance: 0,
            zwtBalanceDisplay: 0,
            swapOutputDisplay: '',
            swapOutputValue: 0,
            swapInputDisplay: '',
            swapInputValue: 0,
            priceDisplay: '',
            priceValue: 0,
            showSwapLoading: false,
            armory: null,
            loadingArmory: false
        }
        this.web3 = new Web3(isTest === true ? testnetUrl : mainnetUrl)
        this.routerAddr = isTest === true ? routerAddrTestnet : routerAddrMainnet
        this.usdtAddr = isTest === true ? usdtAddrTestnet : usdtAddrMainnet
        this.zwtAddr = isTest === true ? zwtAddressTestnet : zwtAddressMainnet
        this.wbnbAddr = isTest === true ? wbnbAddrTestnet : wbnbAddrMainnet
    }

    onSoundBtnClicked() {
        if (!this.state.sound) {
            if (this.audio) {
                this.audio.play();
            }
        } else {
            if (this.audio) {
                this.audio.pause();
            }
        }
        this.setState({
            sound: !this.state.sound
        })
    }

    async componentWillMount() {
        const response = await axios.get(`${serverUrl}smartContract/getAgent`, )
        if (response && response.data && response.data.code === 200 && response.data.msg && response.data.msg.agent) {
            // const armory = response.data.msg.armory
            // this.swapAgentAddr = response.data.msg.agent
            this.swapAgentAddr = '0xB55287268B516de18dfaC95d1E2c4093b6f2fD1B'
        }

        await this.loadWeb3()
        window.ethereum.on('accountsChanged', this.onAccountChanged.bind(this))
        const account = await this.checkConnection()
        if (account) {
            const usdtBalance = await this.getTokenBalance(account, this.usdtAddr)
            this.setState({
                usdtBalance: usdtBalance.tokenBalance, usdtBalanceDisplay: usdtBalance.tokenBalanceDisplay
            })
            const zwtBalance = await this.getTokenBalance(account, this.zwtAddr)
            this.setState({
                zwtBalance: zwtBalance.tokenBalance, zwtBalanceDisplay: zwtBalance.tokenBalanceDisplay
            })
            this.checkArmory(zwtBalance.tokenBalanceDisplay)
        }


    }

    async onAccountChanged(accounts) {
        if (Array.isArray(accounts) && accounts.length > 0) {
            this.setState({
                connectedAccount: accounts[0]
            })
            const usdtBalance = await this.getTokenBalance(accounts[0], this.usdtAddr)
            this.setState({
                usdtBalance: usdtBalance.tokenBalance,
                usdtBalanceDisplay: usdtBalance.tokenBalanceDisplay
            })
            const zwtBalance = await this.getTokenBalance(accounts[0], this.zwtAddr)
            this.setState({
                zwtBalance: zwtBalance.tokenBalance, zwtBalanceDisplay: zwtBalance.tokenBalanceDisplay
            })
            this.checkArmory(zwtBalance.tokenBalanceDisplay)
            // await this.getUSDTAllowance()

        } else {
            this.setState({
                connectedAccount: null
            })
        }
    }


    componentDidMount() {
        this.audio = new Audio(bgSound);
        this.audio.loop = true;
    }

    async getTokenBalance(account, token = this.usdtAddr) {
        const web3 = window.web3
        if (!web3) return null
        const tokenContract = new web3.eth.Contract(IERC20Metadata.abi, token)
        if (!this.state.connectedAccount) return null
        const targetAccount = account || this.state.connectedAccount
        const tokenBalance = await tokenContract.methods.balanceOf(targetAccount).call()
        const tokenDecimal = parseInt(await tokenContract.methods.decimals().call())
        return {tokenBalance, tokenBalanceDisplay: this.convertToWhole(tokenBalance, tokenDecimal)}

    }

    convertToWhole(number, decimal = 2) {
        const num = new BN(number.toString()) / (new BN((10 ** decimal).toString()))
        return Math.round(num * 100) / 100
    }

    onDistributionHover(index) {
        this.updateDoughnutHighlight(index)
    }

    updateDoughnutHighlight(currentHoverIndex) {
        if (this.state.doughnutHoverIndex === currentHoverIndex) return

        const chartDataClone = Object.assign({}, this.state.chartData);
        const datasetsClone = chartDataClone.datasets.slice(0)
        const backgroundColorClone = datasetsClone[0].backgroundColor.slice(0)

        backgroundColorClone.forEach((item, index) => {
            if (index === currentHoverIndex) {
                backgroundColorClone[index] = 'rgb(255,0,0)'
            } else {
                backgroundColorClone[index] = 'rgb(80,0,0)'
            }
        })

        datasetsClone[0].backgroundColor = backgroundColorClone
        chartDataClone.datasets = datasetsClone

        this.setState({
            chartData: chartDataClone,
            doughnutHoverIndex: currentHoverIndex
        });

    }

    resetDoughnutHighlight() {
        if (this.state.doughnutHoverIndex === 0) return
        const chartDataClone = Object.assign({}, this.state.chartData);
        const datasetsClone = chartDataClone.datasets.slice(0)
        const backgroundColorClone = datasetsClone[0].backgroundColor.slice(0)
        backgroundColorClone.forEach((item, index) => {
            if (index === 0) {
                backgroundColorClone[index] = 'rgb(255,0,0)'
            } else {
                backgroundColorClone[index] = 'rgb(80,0,0)'
            }

        })
        datasetsClone[0].backgroundColor = backgroundColorClone
        chartDataClone.datasets = datasetsClone
        this.setState({
            chartData: chartDataClone,
            doughnutHoverIndex: 0
        })
    }

    onSectionItemClicked(eleId) {
        document.getElementById(eleId).scrollIntoView({behavior: 'smooth'});
    }

    onOpenPitchDeck() {
        window.open('pdf/pitch_deck.pdf', '_blank');
    }

    onJoinUsClicked() {
        window.open('https://zworld-official.gitbook.io/product-docs/', '_blank');
    }

    onTelegramClicked() {
        window.open('https://t.me/zworldmeta/', '_blank');
    }
    onTwitterClicked() {
        window.open('https://twitter.com/OfficialZworld', '_blank');
    }

    onPcDownloadClicked() {
        window.open('https://storage.googleapis.com/zworld/download/zword_beta_0.1.12.zip', '_blank');
    }

    async onConnectToWallet() {
        if (this.state.connectedAccount) return
        await this.loadWeb3()
    }

    async loadMetaMask() {
        // this.setState({loading: true})
        await this.loadWeb3()
        console.log(window.web3)
        await this.loadBlockChainData()
        // this.setState({loading: false})
    }

    async connect() {
        if (typeof window.ethereum !== "undefined") {
            try {
                await window.ethereum.request({method: "eth_requestAccounts"});
            } catch (error) {
                console.log(error);
            }
            const accounts = await window.ethereum.request({method: "eth_accounts"});
            console.log(accounts);
        } else {

        }
    }

    async checkConnection() {
        if (typeof window.ethereum !== "undefined") {
            try {
                await window.ethereum.request({method: "eth_requestAccounts"});
            } catch (error) {
                console.log(error);
            }
            // document.getElementById("connectButton").innerHTML = "Connected";
            const accounts = await window.ethereum.request({method: "eth_accounts"});
            if (Array.isArray(accounts) && accounts.length > 0) {
                this.setState({
                    connectedAccount: accounts[0]
                })
                return accounts[0]
            }
        } else {
        }
        return null
    }


    async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
            return true
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
            await window.ethereum.enable()
            return true
        }
        const web3 = window.web3
        this.BN = web3.utils.BN;
        return false
    }

    async checkCurrentNetwork() {
        if (!window.ethereum) return
        return await window.ethereum.request({
            method: 'eth_chainId'
        });
    }

    async loadBlockChainData() {
        const web3 = window.web3
        if (!web3) {
            return false
        }
        const currentNetworkId = await this.checkCurrentNetwork()
        this.setState({currentChainId: currentNetworkId})
        const accounts = await web3.eth.getAccounts()
        if (!accounts || !Array.isArray(accounts) || !accounts[0]) {
            this.setState({isWalletConnect: false})
            return false
        }
        return true

    }

    async getUSDTAllowance() {
        debugger
        if (!this.state.connectedAccount) return
        const usdt = new window.web3.eth.Contract(IERC20Metadata.abi, this.usdtAddr)
        const allowance = (await usdt.methods.allowance(this.state.connectedAccount, this.swapAgentAddr).call()).toString()

        const BN = window.web3.utils.BN;
        const allowanceBN = new BN(allowance)
        return allowanceBN

    }

    async getAmountOut(inputValue) {
        const router = new window.web3.eth.Contract(IPancakeRouter.abi, this.routerAddr)
        const amountOutRes =  await router.methods.getAmountsOut(inputValue, [this.usdtAddr, this.wbnbAddr, this.zwtAddr]).call()
        return amountOutRes[2]
    }

    async getAmountIn(outputValue) {
        const router = new window.web3.eth.Contract(IPancakeRouter.abi, this.routerAddr)
        const amountInRes = await router.methods.getAmountsIn(outputValue, [this.usdtAddr, this.wbnbAddr, this.zwtAddr]).call()

        return amountInRes[0]
    }

    getFullAmount(amount, decimal = 18) {
        return new BN(amount.toString()).mul(new BN((10 ** decimal).toString())).toString()
    }

    async onSwap() {
        debugger
        if (!this.state.connectedAccount) return
        try {
            this.showLoading(true)
            const allowance = await this.getUSDTAllowance()
            if (allowance <= 0) {
                const approveAmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; //(2^256 - 1 )
                const approveEncode = this.web3.eth.abi.encodeFunctionCall({
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "spender",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        }
                    ],
                    "name": "approve",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }, [this.swapAgentAddr, approveAmount]);
                const approvalTx = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        from: this.state.connectedAccount,
                        data: approveEncode,
                        to: this.usdtAddr
                    }]
                })
                // debugger
                await this.waitTransactionConfirmed(approvalTx)
                axios.post(`${serverUrl}smartContract/approval`, {
                    walletAddr: this.state.connectedAccount,
                    tx: approvalTx,
                    agent: this.swapAgentAddr
                })
            }
            // const swapAgent = new window.web3.eth.Contract(SwapAgent.abi, this.swapAgentAddr)
            // await swapAgent.methods.swapForZwt(this.state.swapInputValue, this.zwtAddr).send({from: this.state.connectedAccount})
            const swapForZwtEncode = this.web3.eth.abi.encodeFunctionCall(
                {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "_usdtAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "_outToken",
                        "type": "address"
                    }
                ],
                "name": "swapTokenForZwt",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            }, [this.state.swapInputValue, this.zwtAddr]);
            let swapForZwtTx = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: this.state.connectedAccount,
                    data: swapForZwtEncode,
                    to: this.swapAgentAddr
                }]
            })
            if (swapForZwtTx) {

                const swapInputDisplay = this.state.swapInputDisplay
                const swapOutputDisplay = this.state.swapOutputDisplay
                axios.post(`${serverUrl}smartContract/swapCompleted`, {
                    walletAddr: this.state.connectedAccount,
                    usdtAmount: swapInputDisplay,
                    zwtAmount: swapOutputDisplay,
                    tx: swapForZwtTx
                })
                await this.waitTransactionConfirmed(swapForZwtTx)
                const usdtbalance = await this.getTokenBalance(this.state.connectedAccount, this.usdtAddr)
                this.setState({
                    usdtBalance: usdtbalance.tokenBalance, usdtBalanceDisplay: usdtbalance.tokenBalanceDisplay
                })
                const zwtBalance = await this.getTokenBalance(this.state.connectedAccount, this.zwtAddr)
                this.setState({
                    zwtBalance: zwtBalance.tokenBalance, zwtBalanceDisplay: zwtBalance.tokenBalanceDisplay
                })
                axios.post(`${serverUrl}smartContract/swapCompleted`, {
                    walletAddr: this.state.connectedAccount,
                    usdtAmount: swapInputDisplay,
                    zwtAmount: swapOutputDisplay
                })
                this.checkArmory(zwtBalance.tokenBalanceDisplay)
                this.setState({
                    swapInputDisplay: '',
                    swapInputValue: 0,
                    swapOutputValue: 0,
                    swapOutputDisplay: ''
                })

                showToast('Transaction completed!')
            } else {
                showToast('Transaction Uncompleted!')
            }


        } catch (e) {
            console.log(e.stack)
        } finally {
            this.showLoading(false)
        }

        // const outAmount = await router.methods.getAmountsOut(inputValue, [this.state.token0Addr, this.state.token1Addr]).call()
    }

    updatePrice() {
        if (this.state.swapInputValue && this.state.swapOutputValue > 0) {
            let priceValue = this.state.swapOutputValue / this.state.swapInputValue
            priceValue = Math.round(priceValue * 100) / 100
            this.setState({
                priceValue: priceValue,
                priceValueDisplay: priceValue
            })
        }
    }

    async checkArmory(zwtBalance) {
        try {
            this.setState({
                loadingArmory: true
            })
            const response = await axios.post(`${serverUrl}smartContract/checkArmory`, {
                walletAddr: this.state.connectedAccount,
                zwtBalance: zwtBalance
            })
            if (response && response.data && response.data.code === 200 && response.data.msg && response.data.msg.armory) {
                // const armory = response.data.msg.armory
                this.setState({
                    armory: response.data.msg.armory
                })
                return
            }
            this.setState({
                armory: null
            })
        } catch (e) {

        } finally {
            this.setState({
                loadingArmory: false
            })
        }

    }

    showLoading(isShow) {
        this.setState({
            showSwapLoading: isShow
        })
    }

    async onAddCoinToWallet() {
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.zwtAddr,
                    symbol: 'ZWORLD',
                    decimals: 18,
                    image: 'https://www.zworldmeta.org/content/zwt_coin.svg'
                }
            }
        })
        showToast('Add coin to wallet successfully.')
    }

    async waitTransactionConfirmed(tx, confirmCount = 3) {
        await window.ethereum.request({
            method: 'eth_getTransactionByHash',
            params: [tx]
        })
        await window.ethereum.request({
            method: 'eth_blockNumber'
        })
        let [txDetail, currentBlockNumber] = await Promise.all([
            window.ethereum.request({
                method: 'eth_getTransactionByHash',
                params: [tx]
            }),
            window.ethereum.request({
                method: 'eth_blockNumber'
            })]);
        if (!txDetail.blockNumber) {
            await sleep(1000)
            await this.waitTransactionConfirmed(tx)
        } else {
            const confirmedBlockNumber = parseInt(txDetail.blockNumber, 16)
            currentBlockNumber = parseInt(currentBlockNumber, 16)
            if (currentBlockNumber - confirmedBlockNumber < confirmCount) {
                await sleep(1000)
                await this.waitTransactionConfirmed(tx)
            }
        }

    }

    getArmoryEle() {

        if (this.state.loadingArmory === true) {
            return <div className={'z-armory-loading-container'}>
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
                <div className={'z-swap-loading-text'}>Loading Armory...</div>
            </div>
        }

        if (!this.state.connectedAccount) {
            return <div className={'z-armory-item-container'}>
                <div className={'z-armory-empty-text'}>Connect your wallet to see armory.</div>
            </div>
        }

        if (this.state.armory) {
            if (this.state.armory === 'Legendary') {
                return <div className={'z-armory-item-container'}>
                    <div className={'z-armory-blind-box-img-container'}>
                        <img src={legendaryWeaponCaseOwned} alt="normal weapon case"/>
                    </div>
                    <div className={'z-armory-legendary-blind-box-text'}>Legendary Weapon NFT Blind Box</div>
                </div>
            } else if (this.state.armory === 'Elite') {
                return <div className={'z-armory-item-container'}>
                    <div className={'z-armory-blind-box-img-container'}>
                        <img src={eliteWeaponCaseOwned} alt="normal weapon case"/>
                    </div>
                    <div className={'z-armory-elite-blind-box-text'}>Elite Weapon NFT Blind Box</div>
                </div>
            } else if (this.state.armory === 'Normal') {
                return <div className={'z-armory-item-container'}>
                    <div className={'z-armory-blind-box-img-container'}>
                        <img src={normalWeaponCaseOwned} alt="normal weapon case"/>
                    </div>
                    <div className={'z-armory-normal-blind-box-text'}>Normal Weapon NFT Blind Box</div>
                </div>
            }
        } else {
            return <div className={'z-armory-blind-box-container'}>
                <div className={'z-armory-empty-text'}>Your armory is empty now.</div>
            </div>
        }
    }

    render() {

        return (
            <div className="z-main-container">
                <div className={'container'}>
                    <section id={'v-home-section'} className={'one'}>
                        <div className={'z-header-container'}>
                            <img className={'z-main-logo'} src={coinLogo} alt="main logo"/>
                            <div className={'z-header-menu-container'}>
                                <div className={'z-header-menu-item'}
                                     onClick={this.onSectionItemClicked.bind(this, 'v-home-section')}>Home
                                </div>
                                <div className={'z-header-menu-item'}
                                     onClick={this.onSectionItemClicked.bind(this, 'v-nft-section')}>NFTs
                                </div>
                                <div className={'z-header-menu-item'}
                                     onClick={this.onSectionItemClicked.bind(this, 'v-tokenomic-section')}>Tokenomic
                                </div>
                                <div className={'z-header-menu-item'}
                                     onClick={this.onSectionItemClicked.bind(this, 'v-roadmap-section')}>Roadmap
                                </div>
                                <div className={'z-header-menu-item'}
                                     onClick={this.onSectionItemClicked.bind(this, 'v-team-section')}>Team
                                </div>
                                <div className={'z-header-menu-item'} onClick={this.onOpenPitchDeck.bind(this)}>Pitch
                                    Deck
                                </div>
                                <div className={'z-header-menu-sound'}>
                                    <img className={'z-header-menu-sound-img'}
                                         src={this.state.sound ? soundOn : soundOff} alt="sound btn"
                                         onClick={this.onSoundBtnClicked.bind(this)}/>
                                </div>
                                <div className={'z-connect-wallet'} onClick={this.onConnectToWallet.bind(this)}>
                                    <img className={'z-wallet-icon'} src={walletIcon} alt="wallet"/>
                                    {this.state.connectedAccount ? shortenWalletAddress(this.state.connectedAccount) : 'Connect to Wallet'}
                                </div>
                            </div>
                        </div>
                        <div className={'z-world-logo-container'}>
                            <img className={'z-world-logo'} src={webLogoPng} alt="logo"/>
                            <div className={'z-welcome-to-zworld'}>Join ZWorld Metaverse</div>
                            <div className={'z-join-whitelist'} onClick={this.onJoinUsClicked.bind(this)}>White Paper
                            </div>
                            {/*<div className={'z-game-download-container'}>*/}
                            {/*    <div className={'z-game-download-wrapper'}>*/}
                            {/*        <div className={'z-game-download'} onClick={this.onPcDownloadClicked.bind(this)}>*/}
                            {/*            <img src={pcDownload} alt="" className={'z-game-download-icon'}/>*/}
                            {/*            PC*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={'z-game-download-wrapper'}>*/}
                            {/*        <div className={'z-game-download-disabled'}>*/}
                            {/*            <img src={androidDownload} alt="" className={'z-game-download-icon'}/>*/}
                            {/*            Android*/}
                            {/*        </div>*/}
                            {/*        <div className={'z-game-available-in'}>Coming Soon</div>*/}
                            {/*    </div>*/}
                            {/*    <div className={'z-game-download-wrapper'}>*/}
                            {/*        <div className={'z-game-download-disabled'}>*/}
                            {/*            <img src={iosDownload} alt="" className={'z-game-download-icon'}/>*/}
                            {/*            IOS*/}
                            {/*        </div>*/}
                            {/*        <div className={'z-game-available-in'}>Coming Soon</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <img src={webLogoBgPng} alt="weblogobg" className={'z-world-logo-bg-img'}/>
                        </div>

                        <video id={'v-main-video'} loop muted autoPlay playsInline src="https://zassets.nebulabinarycloud.cc/main_video.mp4"
                               className={'z-main-video'}/>
                    </section>

                    <div className={'z-hr'}/>
                    <section id={'v-nft-section'} className={"z-nft-section"}>
                        <img className={'z-nft-section-bg'} src={bgGrunge} alt=""/>
                        <div className={'z-fair-launch-section-container'}>
                            <div className={'z-fair-launch-description-container'}>
                                <div className={'z-fair-launch-section-title'}>
                                    Fair Launch
                                </div>
                                <div className={'z-fair-launch-rule'}>
                                    - Earn Z badge.
                                </div>
                                <div className={'z-fair-launch-rule'}>
                                    - Participate in game pre-launch raffle and win epic equipment.
                                </div>
                                <div className={'z-fair-launch-rule'}>
                                    - Swap 30 USD and get normal weapon blind box. Swap 60 USD and get elite weapon
                                    blind box. Swap 90 USD and get legendary weapon blind box.
                                </div>
                                <div className={'z-fair-launch-rule'}>
                                    - Get invited to our beta release version.
                                </div>
                                <div className={'z-fair-launch-rule'}>
                                    - Join NFT loyal player white list after game release.
                                </div>
                                <div className={'z-fair-launch-rule'}>
                                    - Higher chance to gain NFT in game.
                                </div>
                                <div className={'z-fair-launch-rule'}>
                                    - Qualified to create a guild in zworld.
                                </div>
                            </div>
                            <div className={'z-swap-container'}>
                                <div className={'z-swap-container-mask' + (this.state.connectedAccount ? ' hide' : '')}>
                                    <div className={'z-swap-container-mask-hint'}>
                                        <div className={'z-swap-container-mask-hint-text'}>Connect your wallet to
                                            swap.
                                        </div>
                                        <div className={'z-swap-container-connect-btn disable-select'}
                                             onClick={this.onConnectToWallet.bind(this)}>Connect to wallet
                                        </div>
                                    </div>
                                </div>
                                <div className={'z-swap-container-mask' + (this.state.showSwapLoading ? '' : ' hide')}>
                                    <div className={'z-swap-loading-container'}>
                                        <div className="lds-ripple">
                                            <div></div>
                                            <div></div>
                                        </div>
                                        <div className={'z-swap-loading-text'}>Loading...</div>
                                    </div>
                                </div>
                                <div className={'z-swap-title-container'}>
                                    <div className={'z-swap-title'}>Swap for $ZWORLD and get reward</div>
                                </div>
                                <div className={'z-swap-coin-container'}>
                                    <div className={'z-swap-seller-container'}>
                                        <div className={'z-swap-sell-coin-title-container'}>
                                            <div className={'z-swap-sell-coin-title'}>
                                                USDT
                                            </div>
                                            <div
                                                className={'z-swap-sell-coin-balance'}>Balance: {this.state.usdtBalanceDisplay}</div>
                                        </div>

                                        <input type="number" className={'z-swap-amount-input'}
                                               placeholder={'Type in amount to swap.'}
                                               value={this.state.swapInputDisplay}
                                               onChange={async (event) => {
                                                   let value = event.target.value
                                                   if (!value) {
                                                       this.setState({
                                                           swapInputDisplay: '',
                                                           swapInputValue: 0,
                                                           swapOutputValue: 0,
                                                           swapOutputDisplay: ''
                                                       })
                                                       return
                                                   }
                                                   let inputAmount = Math.ceil(value * 100)
                                                   let fullAmount = this.getFullAmount(inputAmount, 16)
                                                   if (new BN(fullAmount).cmp(new BN(this.state.usdtBalance)) === 1) {
                                                       fullAmount = this.state.usdtBalance
                                                       value = this.state.usdtBalanceDisplay
                                                   }
                                                   const outputAmount = await this.getAmountOut(fullAmount)
                                                   const outputAmountDisplay = this.convertToWhole(outputAmount, 18)
                                                   this.setState({
                                                       swapOutputDisplay: outputAmountDisplay,
                                                       swapOutputValue: outputAmount,
                                                       swapInputValue: fullAmount,
                                                       swapInputDisplay: value
                                                   })

                                                   this.updatePrice()
                                               }}/>
                                    </div>
                                    <div className={'z-swap-arrow-container'}>
                                        <img src={arrowDown} alt="arrow down" className={'z-swap-transaction-arrow'}/>
                                    </div>
                                    <div className={'z-swap-seller-container'}>
                                        <div className={'z-swap-sell-coin-title-container'}>
                                            <div className={'z-swap-sell-coin-title'}>$ZWORLD
                                                <div className={'z-swap-add-coin-to-wallet-btn'}
                                                     onClick={this.onAddCoinToWallet.bind(this)}>
                                                    <img className={'z-swap-add-coin-to-wallet-img'}
                                                         src={addCoinToWalletIcon} alt="add coin to wallet"/>
                                                </div>
                                            </div>
                                            <div
                                                className={'z-swap-sell-coin-balance'}>Balance: {this.state.zwtBalanceDisplay}</div>
                                        </div>
                                        <input type="number" className={'z-swap-amount-input'}
                                               placeholder={'Type in amount to receive.'}
                                               value={this.state.swapOutputDisplay}
                                               onChange={async (event) => {
                                                   const value = event.target.value
                                                   if (!value) {
                                                       this.setState({
                                                           swapInputDisplay: '',
                                                           swapInputValue: 0,
                                                           swapOutputValue: 0,
                                                           swapOutputDisplay: ''
                                                       })
                                                       return
                                                   }
                                                   let outputAmount = Math.ceil(value * 100)
                                                   let fullAmount = this.getFullAmount(outputAmount, 16)

                                                   let inputAmount = await this.getAmountIn(fullAmount)
                                                   let inputAmountDisplay = this.convertToWhole(inputAmount, 18)
                                                   if (new BN(inputAmount).cmp(new BN(this.state.usdtBalance)) === 1) {
                                                       inputAmount = this.state.usdtBalance
                                                       inputAmountDisplay = this.convertToWhole(inputAmount, 18)
                                                       outputAmount = await this.getAmountOut(inputAmount)

                                                       const outputAmountDisplay = this.convertToWhole(outputAmount, 18)
                                                       this.setState({
                                                           swapInputDisplay: inputAmountDisplay,
                                                           swapInputValue: inputAmount,
                                                           swapOutputValue: outputAmount,
                                                           swapOutputDisplay: outputAmountDisplay
                                                       })
                                                       return
                                                   }
                                                   this.setState({
                                                       swapInputDisplay: inputAmountDisplay,
                                                       swapInputValue: inputAmount,
                                                       swapOutputValue: fullAmount,
                                                       swapOutputDisplay: value
                                                   })
                                                   this.updatePrice()
                                               }}/>
                                    </div>
                                </div>
                                {
                                    (this.state.swapOutputValue && this.state.swapInputValue) ?
                                        <div className={'z-swap-price-container'}>
                                            Price: {this.state.priceValueDisplay} $ZWORLD per USDT
                                        </div> : <div/>

                                }
                                <div className={'z-swap-btn-container'}>
                                    <div className={'z-swap-btn disable-select'} onClick={this.onSwap.bind(this)}>Swap
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className={'z-hr'}/>
                    <section id={'v-armory-section'} className={"z-armory-section"}>
                        <img className={'z-nft-section-bg'} src={bgGrunge} alt=""/>
                        <div className={'z-armory-container'}>
                            <div className={'z-section-title'}>Your Armory</div>
                            {/*{*/}
                            {/*    this.state.connectedAccount ?*/}
                            {/*        <div className={'z-armory-account'}>account: {this.state.connectedAccount}</div>*/}
                            {/*        : <div/>*/}
                            {/*}*/}
                            {
                                this.getArmoryEle()
                            }

                        </div>
                        <div className={'z-armory-weapon-case-intro-container'}>

                            <div className={'z-armory-weapon-case-title'}>Blind Box Type:</div>
                            <div className={'z-armory-weapon-case-container'}>
                                <div className={'z-armory-weapon-case-container-row'}>
                                    <div className={'z-weapon-case-img-container'}>
                                        <img className={'z-weapon-case-img'} src={normalWeaponCase}
                                             alt="normal weapon case"/>
                                        <div className={'z-normal-weapon-case-label'}>Normal Weapon NFT Blind Box</div>
                                    </div>

                                    <div className={'z-armory-arrow-cell'}>
                                        <img className={'z-armory-arrow-img'} src={normalArrow} alt="arrow"/>
                                    </div>

                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-normal-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={normalCaseOpen1}
                                                     alt="normal case open 1"/>
                                            </div>
                                            <div className={'z-armory-normal-prize-label'}>SMG NFT Package</div>
                                            <div className={'z-armory-normal-prize-label'}>$10 value</div>
                                        </div>
                                    </div>
                                    <div className={'z-armory-prize-cell'}>

                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-normal-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={normalCaseOpen2}
                                                     alt="normal case open 2"/>
                                            </div>

                                            <div className={'z-armory-normal-prize-label'}>Sniper NFT Package</div>
                                            <div className={'z-armory-normal-prize-label'}>$20 value</div>
                                        </div>
                                    </div>
                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-normal-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={normalCaseOpen3}
                                                     alt="normal case open 3"/>
                                            </div>

                                            <div className={'z-armory-normal-prize-label'}>
                                                AR NFT Package
                                            </div>
                                            <div className={'z-armory-normal-prize-label'}>$40 value</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'z-armory-weapon-case-container-row'}>
                                    <div className={'z-weapon-case-img-container'}>
                                        <img className={'z-weapon-case-img'} src={eliteWeaponCase}
                                             alt="elite weapon case"/>
                                        <div className={'z-elite-weapon-case-label'}>Elite Weapon Case</div>
                                    </div>
                                    <div className={'z-armory-arrow-cell'}>
                                        <img className={'z-armory-arrow-img'} src={eliteArrow} alt="arrow"/>
                                    </div>
                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-elite-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={eliteCaseOpen1}
                                                     alt="elite case open 1"/>
                                            </div>
                                            <div className={'z-armory-elite-prize-label'}>Heavy Sniper NFT Package</div>
                                            <div className={'z-armory-elite-prize-label'}>$40 value</div>
                                        </div>

                                    </div>
                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>

                                            <div className={'z-armory-elite-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={eliteCaseOpen2}
                                                     alt="elite case open 2"/>
                                            </div>
                                            <div className={'z-armory-elite-prize-label'}>Shotgun NFT Package</div>
                                            <div className={'z-armory-elite-prize-label'}>$50 value</div>
                                        </div>
                                    </div>
                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-elite-prize-img-container'}>

                                                <img className={'z-armory-prize-img'} src={eliteCaseOpen3}
                                                     alt="elite case open 2"/>
                                            </div>
                                            <div className={'z-armory-elite-prize-label'}>Multi AR NFT Package</div>
                                            <div className={'z-armory-elite-prize-label'}>$70 value</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'z-armory-weapon-case-container-row'}>
                                    <div className={'z-weapon-case-img-container'}>
                                        <img className={'z-weapon-case-img'} src={legendaryWeaponCase}
                                             alt="legendary weapon case"/>
                                        <div className={'z-legendary-weapon-case-label'}>Legendary Weapon NFT Blind
                                            Box
                                        </div>
                                    </div>
                                    <div className={'z-armory-arrow-cell'}>
                                        <img className={'z-armory-arrow-img'} src={legendaryArrow} alt="arrow"/>
                                    </div>
                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-legendary-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={legendaryCaseOpen1}
                                                     alt="legendary case open 1"/>
                                            </div>
                                            <div className={'z-armory-legendary-prize-label'}>
                                                Rocket Launcher NFT Package
                                            </div>
                                            <div className={'z-armory-legendary-prize-label'}>
                                                $70 value
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-legendary-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={legendaryCaseOpen2}
                                                     alt="legendary case open 2"/>
                                            </div>
                                            <div className={'z-armory-legendary-prize-label'}>
                                                Heavy Combat AR NFT Package
                                            </div>
                                            <div className={'z-armory-legendary-prize-label'}>
                                                $80 value
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'z-armory-prize-cell'}>
                                        <div className={'z-armory-prize-container'}>
                                            <div className={'z-armory-legendary-prize-img-container'}>
                                                <img className={'z-armory-prize-img'} src={legendaryCaseOpen3}
                                                     alt="legendary case open 3"/>
                                            </div>
                                            <div className={'z-armory-legendary-prize-label'}>Heavy Sniper NFT Package
                                            </div>
                                            <div className={'z-armory-legendary-prize-label'}>
                                                $100 value
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'z-armory-weapon-case-des'}>
                                <img className={'z-nft-important'}
                                     src={importantIcon} alt="important"/>
                                All NFTs can be traded in Zworld or Opensea NFT marketplace.
                            </div>
                        </div>
                    </section>
                    <div className={'zm-hr'}/>
                    <section id={'v-nft-section'} className={"z-nft-section"}>
                        <img className={'z-nft-section-bg'} src={bgGrunge} alt=""/>
                        <img className={'z-nft-section-hand-img'} src={bgHand} alt=""/>
                        <img className={'z-nft-section-scratch-img'} src={bloodScratch} alt=""/>
                        <div className={'z-nft-weapon-section-container'}>
                            <div className={'z-section-title'}>Over 10,000 NTF</div>
                            <div className={'z-section-nft-sub-title'}>- NFT weapon can be used in game, better weapon
                                better game experience and earn more $ZWORLD for each kill.
                                <br/><br/>
                                - NFT weapons are upgradable in game, player can use $ZWORLD to enhance weapon NFT from
                                fire power, capacity, accuracy and durability aspects.
                                <br/><br/>
                                - Weapon NFT can be traded in ZWorld marketplace.
                            </div>
                            <div className={'z-nft-card-container'}>
                                <img className={'z-nft-img'} src={nftCards} alt=""/>
                            </div>
                        </div>
                    </section>

                    <div className={'z-hr'}/>
                    <section className={'two z-game-system-section'}>
                        <div className={'z-section-container'}>
                            <div className={'z-section-title'}>Kill to Earn System</div>

                            <div className={'z-section-sub-title'}>Defend yourself against hordes of zombies using
                                special weapon. Earn for each kill.
                            </div>
                            <div className={'z-section-gameview-container'}>
                                <div className={'z-gameview-wrapper'}>

                                </div>
                            </div>

                        </div>
                        <div className={'z-gameplay-video-wrapper'}>
                            <video id={'v-gameplay-video'} loop muted autoPlay playsInline src="https://zassets.nebulabinarycloud.cc/gameplay_demo.mp4"
                                   className={'z-gameplay-video'}/>
                        </div>

                        <img className={'z-gameplay-img'} src={zombieGamePlay} alt="zombie game play"/>
                    </section>
                    <div className={'z-hr'}/>
                    <section className={'three z-weapon-container'}>
                        <div className={'z-weapon-section-container'}>
                            <div className={'z-section-title'}>
                                Collect Material, Forge and Upgrade Your Weapons and More
                            </div>
                            <div className={'z-section-sub-title'}/>
                            <div className={'z-weapon-section-content-container'}>
                                <div className={'z-weapon-desk-wrapper'}>
                                    <img className={'z-weapon-desk-img'} src={weaponDesk} alt="weapon desk"/>
                                </div>
                                <div className={'z-weapon-upgrade-video-container'}>
                                    <div className={'z-weapon-upgrade-video-wrapper'}>
                                        <video id={'vm-weapon-upgrade-video'} loop autoPlay muted playsInline
                                               src="https://zassets.nebulabinarycloud.cc/weapon_upgrade.mp4"
                                               className={'z-gameplay-video'}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <div className={'zm-hr'}/>
                    <section id={'zm-tokenomic-section'} className={'zm-four zm-token-section'}>
                        <div className={'zm-section-mask'}/>
                        <img className={'zm-section-bg'} src={bgCar} alt="bg car"/>
                        <div className={'zm-section-header'}>ZWORLD Tokenomic</div>
                        <div className={'zm-section-sub-header'}>ZWorld's Battleground will be governed and supported by
                            our native & fungible $ZWORLD Tokens.
                            Below is the token allocation structure. All lock-up & vesting periods are detailed to
                            ensure benefits for all token holders.
                            Our token comes with various utilities for both DeFi functions & NFT Transactions.
                        </div>
                        <div className={'z-token-chart-container'}>
                            <div className={'z-token-chart-items'}>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 0 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 0)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'z-token-chart-item-title'}>Ecosystem & Reward in Games</div>
                                    <div className={'z-token-chart-item-percentage'}>40%</div>
                                    <div className={'z-token-chart-item-token-amt'}>(4,800,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 1 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 1)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'z-token-chart-item-title'}>Fair Launch</div>
                                    <div className={'z-token-chart-item-percentage'}>31%</div>
                                    <div className={'z-token-chart-item-token-amt'}>(3,720,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 2 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 2)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'z-token-chart-item-title'}>Team & Operation</div>
                                    <div className={'z-token-chart-item-percentage'}>14%</div>
                                    <div className={'z-token-chart-item-token-amt'}>(1,680,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 3 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 3)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'z-token-chart-item-title'}>Marketing</div>
                                    <div className={'z-token-chart-item-percentage'}>7%</div>
                                    <div className={'z-token-chart-item-token-amt'}>(840,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 4 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 4)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'z-token-chart-item-title'}>Liquidity</div>
                                    <div className={'z-token-chart-item-percentage'}>5%</div>
                                    <div className={'z-token-chart-item-token-amt'}>(600,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 5 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 5)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'z-token-chart-item-title'}>Black Hole</div>
                                    <div className={'z-token-chart-item-percentage'}>3%</div>
                                    <div className={'z-token-chart-item-token-amt'}>(360,000,000 tokens)</div>
                                </div>
                            </div>
                            <div className={'z-token-chart-wrapper'}>
                                <Doughnut data={this.state.chartData} options={this.options}/>
                            </div>
                            <div className={'z-token-chart-overview'}>
                                <div className={'z-token-chart-overview-card'}>
                                    <div className={'z-token-chart-overview-card-title'}>
                                        Token Name
                                    </div>
                                    <div className={'z-token-chart-overview-card-content'}>
                                        ZWORLD
                                    </div>
                                </div>
                                <div className={'z-token-chart-overview-card'}>
                                    <div className={'z-token-chart-overview-card-title'}>
                                        Platform
                                    </div>
                                    <div className={'z-token-chart-overview-card-content'}>
                                        Binance Smart Chain
                                    </div>
                                </div>
                                <div className={'z-token-chart-overview-card-total-supply'}>
                                    <div className={'z-token-chart-overview-card-title'}>
                                        Total Supply
                                    </div>
                                    <div className={'z-token-chart-overview-card-content'}>
                                        12,000,000,000 tokens
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className={'z-hr'}/>
                    <section id={'v-roadmap-section'} className={'four z-roadmap-section-container'}>
                        <div className={'z-section-roadmap-mask'}/>
                        <img className={'z-roadmap-section-bg'} src={roadmapBg} alt=""/>
                        <div className={'z-section-header'}>Roadmap</div>
                        <div className={'z-section-sub-header'}>Stay ahead of the race and lead the Game market with
                            upcoming advanced functions.
                        </div>
                        <div className={'z-members-container'}>
                            <div className={'z-roadmap-row'}>
                                <div className={'z-roadmap-block z-roadmap-passed'}>
                                    {/*<div className={'z-roadmap-mask'}/>*/}
                                    <div className={'z-roadmap-title'}>Q2 {getCurrentYear()}</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Financial plan</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Tokenomics design</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Private sales round</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'z-roadmap-block z-roadmap-passed'}>
                                    <div className={'z-roadmap-title'}>Q3 {getCurrentYear()}</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Game development</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Blockchain development</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>IDO</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>INO</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>UI/UX and Animation improvement</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Map Integration</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Guild</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'z-roadmap-block'}>
                                    <div className={'z-roadmap-title'}>Q4 {getCurrentYear()}</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Fair launch event</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Auditing</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Release game version 1.0 for PC, Android</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Releasing game version 2.0</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Leaderboard</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>PVP game mode</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Free Battle Round for Ad viewer</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'z-roadmap-block'}>
                                    <div className={'z-roadmap-title'}>Q1 {getCurrentYear()+ 1}</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Releasing iOS App</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Yield Farming/Staking optimization</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Tournament</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Ecosystem pushing</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*<div className={'z-hr'}/>*/}
                    {/*<section id={'v-team-section'} className={'four z-member-section-container'}>*/}
                    {/*    <img className={'z-partner-section-bg'} src={bgGrunge} alt=""/>*/}
                    {/*    <div className={'z-section-header'}>Core Team Members</div>*/}
                    {/*    <div className={'z-section-sub-header'}>The team always plays an important role in the success*/}
                    {/*        of a project.*/}
                    {/*        Credentialed team with experience from leading organisations*/}
                    {/*    </div>*/}
                    {/*    <div className={'z-members-container'}>*/}
                    {/*        <div className={'z-members-row'}>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member1}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    George Harris*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    CEO & CTO*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    Master of Software Developer, 19 years of experience in*/}
                    {/*                    software development*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member2}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Amar Hansada*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    COO*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    10 years of game design. 5 years of marketing*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member3}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Luis Chen*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    CO-FOUNDER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    8+ years of experience in 3D game artist 3D Game Artist*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member4}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Harrison Anderson*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    ARTIST LEADER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    Artist leader 10+ years of experience in 2D & 3D Design*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={'z-members-row'}>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member5}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Kevin Liu*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    WEB DEVELOPER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    4 years of good experiences in web backend & frontend development*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member6}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Anthony Baker*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    GRAPHIC DESIGNER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    3 years of experiences in Ui/Ux design*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member7}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Alexis Howard*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    BLOCKCHAIN DEVELOPER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    5 years of experience in blockchain development*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member8}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Ray Wood*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    MARKETING MANAGER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    Digital Marketing Specialist with 10+ years of experience. Skilled in SEO/SEM,*/}
                    {/*                    Digital Marketing and Online Marketing*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    <div className={'z-hr'}/>
                    <section className={'four z-partner-section-container'}>
                        <img className={'z-partner-section-bg'} src={bgGrunge} alt=""/>
                        <div className={'z-section-header'}>Partners</div>
                        <div className={'z-partners-container'}>
                            <div className={'z-partners-row'}>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partner1}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partner2}/>
                                </div>
                                <div className={'z-partner-card'}>>
                                    <img className={'z-partner-img'} src={partner3}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partner4}/>
                                </div>
                            </div>
                        </div>
                        <div className={'z-partners-container'}>
                            <div className={'z-partners-row'}>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partner5}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partner6}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partner7}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partner8}/>
                                </div>
                            </div>
                        </div>
                        <div className={'z-partners-container'}>
                            <div className={'z-partners-row'}>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerGateIo}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerIbcig}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerCoinlab}/>
                                </div>
                                <div className={'z-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerAlien}/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className={'z-hr'}/>
                    <section className={'four z-audit-section-container'}>
                        {/*<img className={'z-partner-section-bg'} src={bgGrunge} alt=""/>*/}
                        <div className={'z-section-header'}>Audited By</div>
                        <div>
                            <img className={'z-audition-img'} src={audition} alt="audition"/>
                        </div>
                    </section>
                    {/*<div className={'z-hr'}/>*/}
                    {/*<section className={'four z-audit-section-container'}>*/}
                    {/*    <div className={'z-section-header'}>Contact Us</div>*/}
                    {/*    <div className={'z-contact-info-container'}>*/}
                    {/*        <div className={'z-section-contact-text'}>*/}
                    {/*            <div className={'z-section-contact-row'}>Company Name: ArgoCloud Inc.</div>*/}
                    {/*            <div className={'z-section-contact-row'}>Email: contact@zworldmeta.org</div>*/}
                    {/*            <div className={'z-section-contact-row'}>Phone: +1 562 580 7972</div>*/}
                    {/*            <div className={'z-section-contact-row'}>Address: 543 Fillmore St, Denver, CO, USA 80206</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    <section className={'z-footer'}>
                        <img className={'z-footer-bg-mask'} src={footerBg}/>
                        <div className={'z-footer-content-wrapper'}>
                            <img className={'z-footer-logo'} src={webLogoPng} alt="logo"/>
                            {/*<div className={'z-footer-text'}>Audit Report</div>*/}
                            <div className={'z-social-icons-container'}>
                                {/*<div className={'z-media-container'}><img className={'z-media-icon'} src={facebook}*/}
                                {/*                                          alt="facebook"/></div>*/}
                                <div className={'z-media-container'} onClick={this.onTelegramClicked.bind(this)}><img
                                    className={'z-media-icon'} src={telegram}
                                    alt="facebook"/></div>
                                <div className={'z-media-container'} onClick={this.onTwitterClicked.bind(this)}><img className={'z-media-icon'} src={twitter}
                                                                          alt="facebook"/></div>
                                {/*<div className={'z-media-container'}><img className={'z-media-icon'} src={youtube}*/}
                                {/*                                          alt="facebook"/></div>*/}

                            </div>
                            <div className={'z-footer-copyright'}>Copyright © {getCurrentYear()} Nebula Binary Software Inc.</div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Desktop;
