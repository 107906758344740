import React, {Component} from 'react';
import './Download.css';


class Download extends Component {

    componentDidMount() {

    }

    render() {

        return (
            <div className="z-download-container">
                
            </div>
        );
    }
}

export default Download;
