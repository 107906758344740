import React, {Component} from 'react';
import './Mobile.css';
import webLogoPng from '../img/web_logo.png';
import coinLogo from '../img/coinLogo.svg';
import menuIcon from '../img/menu.svg';
import grungeMobileBG from '../img/web_logo_bg.png';
import nftCardsMobile from '../img/nft_cards_mobile.png';
import bgSound from '../sounds/bg_sound.mp3'
import soundFilling from '../img/mobile/sound-filling.svg'
import soundMute from '../img/mobile/sound-mute.svg'
import zombieGamePlay from '../img/background/web_bg_gameplay.png'
import member1 from '../img/team/member1.png'
import member2 from '../img/team/member2.png'
import member3 from '../img/team/member3.png'
import member4 from '../img/team/member4.png'
import member5 from '../img/team/member5.png'
import member6 from '../img/team/member6.png'
import member7 from '../img/team/member7.png'
import member8 from '../img/team/member8.png'
import partner1 from '../img/partners/1TrustFi Logo - White-Yellow.png'
import partner2 from '../img/partners/binstarter.png'
import partner3 from '../img/partners/blockchain-army.png'
import partner4 from '../img/partners/cspdao.png'
import partner5 from '../img/partners/dreamboat.png'
import partner6 from '../img/partners/GFM.png'
import partner7 from '../img/partners/iCe logo.png'
import partner8 from '../img/partners/thelighthouse.png'
import partnerGateIo from '../img/partners/gateio.png'
import partnerIbcig from '../img/partners/ibcig.png'
import partnerCoinlab from '../img/partners/CoinLAB.png'
import partnerAlien from '../img/partners/alien.png'
import audition from '../img/audition/verchains.png'
import timelineDot from '../img/timelineDot.svg'
import unfinishedCheck from '../img/unfinished_check.svg'
import finishedCheck from '../img/finished_check.svg'
import footerBg from '../img/foot_bg.png'
import facebook from '../img/social/facebook.svg'
import telegram from '../img/social/telegram.svg'
import twitter from '../img/social/twitter.svg'
import youtube from '../img/social/youtube.svg'
import bgHand from '../img/background/web_bg_hand.png'
import bloodScratch from '../img/background/blood_scratch.png'
import bgCar from '../img/background/web_bg_car.png'
import bgGrunge from '../img/background/grunge_texture.png'
import roadmapBg from '../img/background/roadmap_bg.png'
import weaponDesk from '../img/weapon_desk.png'
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import BN from "bn.js";
import arrowDown from "../img/arrow_down.svg";
import addCoinToWalletIcon from "../img/add_coin_to_wallet.svg";
import Web3 from "web3";
import {shortenWalletAddress, showToast, sleep, toFixed, getCurrentYear} from "../utils/methods";
import axios from "axios";
import {
    isTest,
    mainnetId,
    mainnetUrl,
    routerAddrMainnet,
    routerAddrTestnet,
    serverUrl,
    wbnbAddrMainnet,
    wbnbAddrTestnet,
    testnetId,
    testnetUrl,
    usdtAddrMainnet,
    usdtAddrTestnet,
    zwtAddressMainnet,
    zwtAddressTestnet
} from "../utils/constants";
import walletIcon from "../img/wallet.svg";
import normalWeaponCase from "../img/weapons/normal_weapon_case.png";
import normalArrow from "../img/weapons/normal_arrow.svg";
import normalCaseOpen1 from "../img/weapons/normal_case_open_1.png";
import normalCaseOpen2 from "../img/weapons/normal_case_open_2.png";
import normalCaseOpen3 from "../img/weapons/normal_case_open_3.png";
import eliteWeaponCase from "../img/weapons/elite_weapon_case.png";
import eliteArrow from "../img/weapons/elite_arrow.svg";
import eliteCaseOpen1 from "../img/weapons/elite_case_open_1.png";
import eliteCaseOpen2 from "../img/weapons/elite_case_open_2.png";
import eliteCaseOpen3 from "../img/weapons/elite_case_open_3.png";
import legendaryWeaponCase from "../img/weapons/legendary_weapon_case.png";
import legendaryArrow from "../img/weapons/legendary_arrow.svg";
import legendaryCaseOpen1 from "../img/weapons/legendary_case_open_1.png";
import legendaryCaseOpen2 from "../img/weapons/legendary_case_open_2.png";
import legendaryCaseOpen3 from "../img/weapons/legendary_case_open_3.png";
import importantIcon from "../img/important.svg";
import legendaryWeaponCaseOwned from "../img/weapons/legendary_weapon_case_owned.png";
import eliteWeaponCaseOwned from "../img/weapons/elite_weapon_case_owned.png";
import normalWeaponCaseOwned from "../img/weapons/normal_weapon_case_owned.png";
import IPancakeRouter from "../abis/IPancakeRouter02.json";

ChartJS.register(ArcElement, Tooltip, Legend);


class Mobile extends Component {

    audio;

    web3

    options = {
        onHover: (evt, item, legend) => {
            if (item.length > 0) {
                this.updateDoughnutHighlight(item[0].index)
            } else {
                this.resetDoughnutHighlight()
            }
        },
        plugins: {
            tooltip: {enabled: false}
        }
    }

    constructor() {
        super();
        this.state = {
            sound: false,
            chartData: {
                datasets: [
                    {
                        label: '# of Votes',
                        data: [40, 31, 14, 7, 5, 3],
                        backgroundColor: [
                            'rgb(255,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)',
                            'rgb(80,0,0)'
                        ],
                        borderColor: [
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)',
                            'rgba(0, 0, 0, 1)'
                        ],
                        borderWidth: 2,
                    }
                ],
            },
            doughnutHoverIndex: 0,
            connectedAccount: null,
            usdtBalance: 0,
            usdtBalanceDisplay: 0,
            usdtAllowance: null,
            zwtBalance: 0,
            zwtBalanceDisplay: 0,
            swapOutputDisplay: '',
            swapOutputValue: 0,
            swapInputDisplay: '',
            swapInputValue: 0,
            priceDisplay: '',
            priceValue: 0,
            showSwapLoading: false,
            armory: null,
            loadingArmory: false
        }
        this.web3 = new Web3(isTest === true ? testnetUrl : mainnetUrl)
        this.routerAddr = isTest === true ? routerAddrTestnet : routerAddrMainnet
        this.usdtAddr = isTest === true ? usdtAddrTestnet : usdtAddrMainnet
        this.zwtAddr = isTest === true ? zwtAddressTestnet : zwtAddressMainnet
        this.wbnbAddr = isTest === true ? wbnbAddrTestnet : wbnbAddrMainnet
    }

    componentDidMount() {
        this.audio = new Audio(bgSound);
        this.audio.loop = true;
        document.getElementById('vm-main-video').play();
        // document.getElementById('vm-weapon-upgrade-video').play();
        document.getElementById('vm-gameplay-video').play();

    }

    onDistributionHover(index) {
        this.updateDoughnutHighlight(index)
    }

    getFullAmount(amount, decimal = 18) {
        return new BN(amount.toString()).mul(new BN((10 ** decimal).toString())).toString()
    }

    showLoading(isShow) {
        this.setState({
            showSwapLoading: isShow
        })
    }

    async getAmountOut(inputValue) {
        const getAmountsOutEncode = this.web3.eth.abi.encodeFunctionCall({
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "amountIn",
                    "type": "uint256"
                },
                {
                    "internalType": "address[]",
                    "name": "path",
                    "type": "address[]"
                }
            ],
            "name": "getAmountsOut",
            "outputs": [
                {
                    "internalType": "uint256[]",
                    "name": "amounts",
                    "type": "uint256[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }, [inputValue, [this.usdtAddr, this.wbnbAddr, this.zwtAddr]]);
        let amountOutRes = await window.ethereum.request({
            method: 'eth_call',
            params: [
                {
                    data: getAmountsOutEncode,
                    to: this.routerAddr,
                },
                'latest'
            ],
        });
        const decoded = window.web3.eth.abi.decodeParameters([{
            type: 'uint256[]',
            name: 'amounts'
        }], amountOutRes);
        return decoded.amounts[2]
    }

    async getAmountIn(outputValue) {
        const getAmountsInEncode = this.web3.eth.abi.encodeFunctionCall({
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "amountOut",
                    "type": "uint256"
                },
                {
                    "internalType": "address[]",
                    "name": "path",
                    "type": "address[]"
                }
            ],
            "name": "getAmountsIn",
            "outputs": [
                {
                    "internalType": "uint256[]",
                    "name": "amounts",
                    "type": "uint256[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }, [outputValue, [this.usdtAddr, this.wbnbAddr, this.zwtAddr]]);
        let amountInRes = await window.ethereum.request({
            method: 'eth_call',
            params: [
                {
                    data: getAmountsInEncode,
                    to: this.routerAddr,
                },
                'latest'
            ],
        });
        const decoded = window.web3.eth.abi.decodeParameters([{
            type: 'uint256[]',
            name: 'amounts'
        }], amountInRes);
        return decoded.amounts[0]
    }

    updatePrice() {
        if (this.state.swapInputValue && this.state.swapOutputValue > 0) {
            let priceValue = this.state.swapOutputValue / this.state.swapInputValue
            priceValue = Math.round(priceValue * 100) / 100
            this.setState({
                priceValue: priceValue,
                priceValueDisplay: priceValue
            })
        }
    }

    async onConnectToWallet() {
        // if (this.state.connectedAccount) return
        // await this.loadWeb3()
        const chainId = await this.getChainId()
        if (isTest === true) {
            if (chainId === testnetId) {
                await this.getConnectedAccount()
            } else {
                this.changeChain(isTest)
            }
        } else {
            if (chainId === mainnetId) {
                await this.getConnectedAccount()
                this.changeChain(isTest)
            }
        }

    }

    async getConnectedAccount() {
        const response = await window.ethereum.request({method: 'eth_requestAccounts'});
        if (Array.isArray(response) && response.length > 0) {
            this.setState({
                connectedAccount: response[0]
            })
        }
    }

    async componentWillMount() {
        const response = await axios.get(`${serverUrl}smartContract/getAgent`, )
        if (response && response.data && response.data.code === 200 && response.data.msg && response.data.msg.agent) {
            // const armory = response.data.msg.armory
            this.swapAgentAddr = response.data.msg.agent
        }
        const chainId = await this.getChainId()
        if (isTest === true) {
            if (chainId !== testnetId) {
                this.changeChain(true)
            } else {
                await this.init()
            }
        } else {
            if (chainId !== mainnetId) {
                this.changeChain(false)
            } else {
                await this.init()
            }
        }

        window.ethereum.on('chainChanged', (_chainId) => {
            if (isTest === true) {
                if (_chainId !== testnetId) {
                    this.changeChain(true)
                }
            } else {
                if (_chainId !== mainnetId) {
                    this.changeChain(false)
                }
            }
        });

    }

    async init() {
        await this.loadWeb3()
        window.ethereum.on('accountsChanged', this.onAccountChanged.bind(this))
        const account = await this.checkConnection()
        if (account) {
            const usdtBalance = await this.getTokenBalance(account, this.usdtAddr)
            this.setState({
                usdtBalance: usdtBalance.tokenBalance, usdtBalanceDisplay: usdtBalance.tokenBalanceDisplay
            })
            const zwtBalance = await this.getTokenBalance(account, this.zwtAddr)
            this.setState({
                zwtBalance: zwtBalance.tokenBalance, zwtBalanceDisplay: zwtBalance.tokenBalanceDisplay
            })
            this.checkArmory(zwtBalance.tokenBalanceDisplay)
        }
    }

    async getChainId() {
        return (await window.ethereum.request({method: 'eth_chainId'}))
    }

    async changeChain(isTest) {
        if (isTest === true) {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainName: 'BSC',
                    rpcUrls: [testnetUrl],
                    chainId: testnetId
                }],
            });
            const chainId = await this.getChainId()
            if (chainId === testnetId) {
                this.init()
            } else {
                this.setState({
                    connectedAccount: null
                })
            }
        } else {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainName: 'BSC',
                    rpcUrls: [mainnetUrl],
                    chainId: mainnetId
                }],
            });
            const chainId = await this.getChainId()
            if (chainId === mainnetId) {
                this.init()
            } else {
                this.setState({
                    connectedAccount: null
                })
            }
        }
    }

    async getTokenBalance(account, token = this.usdtAddr) {
        if (!this.web3) return null
        if (!this.state.connectedAccount) return null
        const targetAccount = account || this.state.connectedAccount

        const getTokenBalanceEncode = this.web3.eth.abi.encodeFunctionCall({
            "inputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }, [targetAccount]);

        let tokenBalance = await window.ethereum.request({
            method: 'eth_call',
            params: [
                {
                    data: getTokenBalanceEncode,
                    to: token,
                },
                'latest'
            ],
        });
        const getTokenDecimalsEncode = this.web3.eth.abi.encodeFunctionCall({
            "inputs": [],
            "name": "decimals",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }, []);
        let tokenDecimal = await window.ethereum.request({
            method: 'eth_call',
            params: [
                {
                    data: getTokenDecimalsEncode,
                    to: token
                },
                "latest"
            ],
        });
        tokenDecimal = parseInt(tokenDecimal, 16)
        return {
            tokenBalance: parseInt(tokenBalance, 16).toString(),
            tokenBalanceDisplay: this.convertToWhole(toFixed(parseInt(tokenBalance, 16)), tokenDecimal)
        }

    }

    convertToWhole(number, decimal = 2) {
        const num = new BN(number.toString()) / (new BN((10 ** decimal).toString()))
        return Math.round(num * 100) / 100
    }

    async checkConnection() {
        if (typeof window.ethereum !== "undefined") {
            try {
                await window.ethereum.request({method: "eth_requestAccounts"});
            } catch (error) {
                console.log(error);
            }
            // document.getElementById("connectButton").innerHTML = "Connected";
            const accounts = await window.ethereum.request({method: "eth_accounts"});
            if (Array.isArray(accounts) && accounts.length > 0) {
                this.setState({
                    connectedAccount: accounts[0]
                })
                return accounts[0]
            }
        } else {
        }
        return null
    }

    async onAccountChanged(accounts) {
        if (Array.isArray(accounts) && accounts.length > 0) {
            this.setState({
                connectedAccount: accounts[0]
            })
            const usdtBalance = await this.getTokenBalance(accounts[0], this.usdtAddr)
            this.setState({
                usdtBalance: usdtBalance.tokenBalance,
                usdtBalanceDisplay: usdtBalance.tokenBalanceDisplay
            })
            const zwtBalance = await this.getTokenBalance(accounts[0], this.zwtAddr)
            this.setState({
                zwtBalance: zwtBalance.tokenBalance, zwtBalanceDisplay: zwtBalance.tokenBalanceDisplay
            })
            this.checkArmory(zwtBalance.tokenBalanceDisplay)
            // await this.getUSDTAllowance()

        } else {
            this.setState({
                connectedAccount: null
            })
        }
    }

    async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
            return true
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
            await window.ethereum.enable()
            return true
        }
        // const web3 = window.web3
        // this.BN = web3.utils.BN;
        return false
    }

    async onAddCoinToWallet() {
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: this.zwtAddr,
                    symbol: 'ZWORLD',
                    decimals: 18,
                    image: 'https://www.zworldmeta.org/content/zwt_coin.svg'
                }
            }
        })
        showToast('Add coin to wallet successfully.', null, true)
    }

    async getUSDTAllowance() {
        if (!this.state.connectedAccount) return

        const allowanceEncode = this.web3.eth.abi.encodeFunctionCall({
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }, [this.state.connectedAccount, this.swapAgentAddr]);

        let allowance = await window.ethereum.request({
            method: 'eth_call',
            params: [{
                data: allowanceEncode,
                to: this.usdtAddr
            }, "latest"]
        })

        return new BN(toFixed(parseInt(allowance, 16)).toString())

    }


    async onSwap() {
        if (!this.state.connectedAccount) return
        if (this.state.swapInputValue === 0) return
        try {
            this.showLoading(true)
            const allowance = await this.getUSDTAllowance()
            if (allowance <= 0) {
                const approveAmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; //(2^256 - 1 )
                const approveEncode = this.web3.eth.abi.encodeFunctionCall({
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "spender",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        }
                    ],
                    "name": "approve",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }, [this.swapAgentAddr, approveAmount]);
                const approvalTx = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        from: this.state.connectedAccount,
                        data: approveEncode,
                        to: this.usdtAddr
                    }]
                })
                await this.waitTransactionConfirmed(approvalTx)
                axios.post(`${serverUrl}smartContract/approval`, {
                    walletAddr: this.state.connectedAccount,
                    tx: approvalTx,
                    agent: this.swapAgentAddr
                })
            }
            const swapForZwtEncode = this.web3.eth.abi.encodeFunctionCall({
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "_usdtAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "_outToken",
                        "type": "address"
                    }
                ],
                "name": "swapTokenForZwt",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            }, [this.state.swapInputValue, this.zwtAddr]);
            let swapForZwtTx = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: this.state.connectedAccount,
                    data: swapForZwtEncode,
                    to: this.swapAgentAddr
                }]
            })
            if (swapForZwtTx) {
                const swapInputDisplay = this.state.swapInputDisplay
                const swapOutputDisplay = this.state.swapOutputDisplay
                axios.post(`${serverUrl}smartContract/swapCompleted`, {
                    walletAddr: this.state.connectedAccount,
                    usdtAmount: swapInputDisplay,
                    zwtAmount: swapOutputDisplay,
                    tx: swapForZwtTx
                })
                await this.waitTransactionConfirmed(swapForZwtTx)
                const usdtbalance = await this.getTokenBalance(this.state.connectedAccount, this.usdtAddr)
                this.setState({
                    usdtBalance: usdtbalance.tokenBalance, usdtBalanceDisplay: usdtbalance.tokenBalanceDisplay
                })
                const zwtBalance = await this.getTokenBalance(this.state.connectedAccount, this.zwtAddr)
                this.setState({
                    zwtBalance: zwtBalance.tokenBalance, zwtBalanceDisplay: zwtBalance.tokenBalanceDisplay
                })

                this.checkArmory(zwtBalance.tokenBalanceDisplay)
                this.setState({
                    swapInputDisplay: '',
                    swapInputValue: 0,
                    swapOutputValue: 0,
                    swapOutputDisplay: ''
                })

                showToast('Transaction Completed!')
            } else {
                showToast('Transaction Uncompleted!')
            }

        } catch (e) {

        } finally {
            this.showLoading(false)
        }

        // const outAmount = await router.methods.getAmountsOut(inputValue, [this.state.token0Addr, this.state.token1Addr]).call()
    }

    async waitTransactionConfirmed(tx, confirmCount = 3) {
        await window.ethereum.request({
            method: 'eth_getTransactionByHash',
            params: [tx]
        })
        await window.ethereum.request({
            method: 'eth_blockNumber'
        })
        let [txDetail, currentBlockNumber] = await Promise.all([
            window.ethereum.request({
                method: 'eth_getTransactionByHash',
                params: [tx]
            }),
            window.ethereum.request({
                method: 'eth_blockNumber'
            })]);
        if (!txDetail.blockNumber) {
            await sleep(1000)
            await this.waitTransactionConfirmed(tx)
        } else {
            const confirmedBlockNumber = parseInt(txDetail.blockNumber, 16)
            currentBlockNumber = parseInt(currentBlockNumber, 16)
            if (currentBlockNumber - confirmedBlockNumber < confirmCount) {
                await sleep(1000)
                await this.waitTransactionConfirmed(tx)
            }
        }

    }

    async checkArmory(zwtBalance) {
        try {
            this.setState({
                loadingArmory: true
            })
            const response = await axios.post(`${serverUrl}smartContract/checkArmory`, {
                walletAddr: this.state.connectedAccount,
                zwtBalance: zwtBalance
            })
            if (response && response.data && response.data.code === 200 && response.data.msg && response.data.msg.armory) {
                // const armory = response.data.msg.armory
                this.setState({
                    armory: response.data.msg.armory
                })
                return
            }
            this.setState({
                armory: null
            })
        } catch (e) {

        } finally {
            this.setState({
                loadingArmory: false
            })
        }

    }

    getArmoryEle() {

        if (this.state.loadingArmory === true) {
            return <div className={'z-armory-loading-container'}>
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
                <div className={'z-swap-loading-text'}>Loading Armory...</div>
            </div>
        }

        if (!this.state.connectedAccount) {
            return <div className={'z-armory-item-container'}>
                <div className={'z-armory-empty-text'}>Connect to wallet to see your armory.</div>
            </div>
        }

        if (this.state.armory) {
            if (this.state.armory === 'Legendary') {
                return <div className={'z-armory-item-container'}>
                    <div className={'z-armory-blind-box-img-container'}>
                        <img className={'zm-armory-blind-box-img'} src={legendaryWeaponCaseOwned}
                             alt="legendary weapon case"/>
                    </div>
                    <div className={'zm-armory-blind-box-text zm-legendary-weapon-blind-box'}>Legendary Weapon NFT Blind
                        Box
                    </div>
                </div>
            } else if (this.state.armory === 'Elite') {
                return <div className={'z-armory-item-container'}>
                    <div className={'z-armory-blind-box-img-container'}>
                        <img className={'zm-armory-blind-box-img'} src={eliteWeaponCaseOwned} alt="elite weapon case"/>
                    </div>
                    <div className={'zm-armory-blind-box-text zm-elite-weapon-blind-box'}>Elite Weapon NFT Blind Box
                    </div>
                </div>
            } else if (this.state.armory === 'Normal') {
                return <div className={'z-armory-item-container'}>
                    <div className={'z-armory-blind-box-img-container'}>
                        <img className={'zm-armory-blind-box-img'} src={normalWeaponCaseOwned}
                             alt="normal weapon case"/>
                    </div>
                    <div className={'zm-armory-blind-box-text zm-normal-weapon-blind-box'}>Normal Weapon NFT Blind Box
                    </div>
                </div>
            }
        } else {
            return <div className={'z-armory-blind-box-container'}>
                <div className={'z-armory-empty-text'}>Your armory is empty now.</div>
            </div>
        }
    }

    updateDoughnutHighlight(currentHoverIndex) {
        if (this.state.doughnutHoverIndex === currentHoverIndex) return

        const chartDataClone = Object.assign({}, this.state.chartData);
        const datasetsClone = chartDataClone.datasets.slice(0)
        const backgroundColorClone = datasetsClone[0].backgroundColor.slice(0)

        backgroundColorClone.forEach((item, index) => {
            if (index === currentHoverIndex) {
                backgroundColorClone[index] = 'rgb(255,0,0)'
            } else {
                backgroundColorClone[index] = 'rgb(80,0,0)'
            }
        })

        datasetsClone[0].backgroundColor = backgroundColorClone
        chartDataClone.datasets = datasetsClone

        this.setState({
            chartData: chartDataClone,
            doughnutHoverIndex: currentHoverIndex,
            showMenu: false
        });

    }

    resetDoughnutHighlight() {
        if (this.state.doughnutHoverIndex === 0) return
        const chartDataClone = Object.assign({}, this.state.chartData);
        const datasetsClone = chartDataClone.datasets.slice(0)
        const backgroundColorClone = datasetsClone[0].backgroundColor.slice(0)
        backgroundColorClone.forEach((item, index) => {
            if (index === 0) {
                backgroundColorClone[index] = 'rgb(255,0,0)'
            } else {
                backgroundColorClone[index] = 'rgb(80,0,0)'
            }

        })
        datasetsClone[0].backgroundColor = backgroundColorClone
        chartDataClone.datasets = datasetsClone
        this.setState({
            chartData: chartDataClone,
            doughnutHoverIndex: 0
        })
    }

    menuClicked() {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    onNFTClicked(eleId) {
        document.getElementById(eleId).scrollIntoView({behavior: 'smooth'});
        this.setState({
            showMenu: false
        })
    }

    soundClicked() {
        if (!this.state.sound) {
            if (this.audio) {
                this.audio.play();
            }
        } else {
            if (this.audio) {
                this.audio.pause();
            }
        }
        this.setState({
            sound: !this.state.sound
        })
    }

    onPitchDeckClicked() {
        window.open('pdf/pitch_deck.pdf', '_blank');
    }

    joinUsClicked() {
        window.open('https://zworld-official.gitbook.io/product-docs/', '_blank');
    }

    onTelegramClicked() {
        window.open('https://t.me/zworldmeta/', '_blank');

    }

    onTwitterClicked() {
        window.open('https://twitter.com/OfficialZworld', '_blank');

    }

    onPcDownloadClicked() {
        window.open('https://storage.googleapis.com/zworld/download/zword_beta_0.1.12.zip', '_blank');
    }

    render() {

        return (
            <div className="z-main-container">
                <div id={'zm-main-container'} className={'container'}>
                    <section className={'zm-one'}>
                        <div className={'zm-header-container'}>
                            <div className={'zm-header-bar'}>
                                <img className={'zm-main-logo'} src={coinLogo} alt="main logo"/>

                                <div className={'zm-menu-container'}>
                                    <div className={'zm-pitch-deck-btn'}
                                         onClick={this.onConnectToWallet.bind(this)}>
                                        <img className={'z-wallet-icon'} src={walletIcon}
                                             alt="wallet"/> {this.state.connectedAccount ? shortenWalletAddress(this.state.connectedAccount) : 'Connect to Wallet'}
                                    </div>
                                    <img className={'zm-menu-sound-img'}
                                         src={this.state.sound ? soundMute : soundFilling} alt=""
                                         onClick={this.soundClicked.bind(this)}/>
                                    <img className={'zm-menu-img'}
                                         src={menuIcon} alt=""
                                         onClick={this.menuClicked.bind(this)}/>
                                </div>

                            </div>
                            <div className={'zm-dropdown-menu-container ' + (this.state.showMenu ? 'show-menu' : '')}>
                                <div className={'zm-dropdown-menu-item'}
                                     onClick={this.onNFTClicked.bind(this, 'zm-nft-section')}>NFTs
                                </div>
                                <div className={'zm-dropdown-menu-item'}
                                     onClick={this.onNFTClicked.bind(this, 'zm-tokenomic-section')}>Tokenomic
                                </div>
                                <div className={'zm-dropdown-menu-item'}
                                     onClick={this.onNFTClicked.bind(this, 'zm-roadmap-section')}>Roadmap
                                </div>
                                <div className={'zm-dropdown-menu-item'}
                                     onClick={this.onNFTClicked.bind(this, 'zm-team-section')}>Team
                                </div>
                                <div className={'zm-dropdown-menu-item'}
                                     onClick={this.onPitchDeckClicked.bind(this, 'zm-team-section')}>Pitch Deck
                                </div>
                            </div>
                        </div>
                        <div className={'zm-world-logo-container'}>
                            <img className={'zm-world-logo'} src={webLogoPng} alt="logo"/>
                            <div className={'zm-welcome-to-zworld'}>Join ZWorld Metaverse</div>

                            {/*<div className={'zm-game-download-container'}>*/}
                            {/*    <div className={'zm-game-download-wrapper'}>*/}
                            {/*        <div className={'zm-game-download'} onClick={this.onPcDownloadClicked.bind(this)}>*/}
                            {/*            <img src={pcDownload} alt="" className={'zm-game-download-icon'}/>*/}
                            {/*            PC*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*    <div className={'zm-game-download-wrapper'}>*/}
                            {/*        <div className={'zm-game-download-disabled'}>*/}
                            {/*            <img src={androidDownload} alt="" className={'zm-game-download-icon'}/>*/}
                            {/*            Android*/}
                            {/*        </div>*/}
                            {/*        <div className={'zm-game-available-in'}>Coming Soon</div>*/}
                            {/*    </div>*/}
                            {/*    <div className={'zm-game-download-wrapper'}>*/}
                            {/*        <div className={'zm-game-download-disabled'}>*/}
                            {/*            <img src={iosDownload} alt="" className={'zm-game-download-icon'}/>*/}
                            {/*            IOS*/}
                            {/*        </div>*/}
                            {/*        <div className={'zm-game-available-in'}>Coming Soon</div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                            <div className={'zm-join-whitelist'} onClick={this.joinUsClicked.bind(this)}>White Paper
                            </div>
                        </div>
                        <img src={grungeMobileBG} alt="weblogobg" className={'zm-world-logo-bg-img'}/>
                        {/*<VideoPlayer muted {...videoJsOptions} videoClassName={'z-video-container'}/>*/}
                        <div dangerouslySetInnerHTML={{
                            __html: `
                            <video
                                id='vm-main-video'
                                loop
                                muted
                                autoPlay
                                autoplay="autoplay"
                                playsinline
                                src="https://zassets.nebulabinarycloud.cc/main_video.mp4"
                                class="zm-main-video"
                            />,
                          `
                        }}></div>
                        {/*<video id={'vm-main-video'} loop autoPlay muted playsinline src="video/main_video.mp4"*/}
                        {/*       className={'zm-main-video'}/>*/}
                    </section>
                    <div className={'zm-hr'}/>
                    {/*<section id={'v-nft-section'} className={"zm-nft-section"}>*/}
                    {/*    <img className={'z-nft-section-bg'} src={bgGrunge} alt=""/>*/}
                    {/*    <div className={'zm-fair-launch-section-container'}>*/}
                    {/*        <div className={'z-fair-launch-description-container'}>*/}
                    {/*            <div className={'z-fair-launch-section-title'}>*/}
                    {/*                Fair Launch*/}
                    {/*            </div>*/}
                    {/*            <div className={'zm-fair-launch-rule-table'}>*/}
                    {/*                <div className={'zm-fair-launch-rule-table-row'}>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>-</div>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>*/}
                    {/*                        <div className={'z-fair-launch-rule'}>*/}
                    {/*                            Earn Z badge.*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-fair-launch-rule-table-row'}>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>-</div>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>*/}
                    {/*                        <div className={'z-fair-launch-rule'}>*/}
                    {/*                            Participate in game pre-launch raffle and win epic equipment.*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-fair-launch-rule-table-row'}>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>-</div>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>*/}
                    {/*                        <div className={'z-fair-launch-rule'}>*/}
                    {/*                            Swap 30 USD and get normal weapon blind box. Swap 60 USD and get elite*/}
                    {/*                            weapon*/}
                    {/*                            blind box. Swap 90 USD and get legendary weapon blind box.*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-fair-launch-rule-table-row'}>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>-</div>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>*/}
                    {/*                        <div className={'z-fair-launch-rule'}>*/}
                    {/*                            Get invited to our beta release version.*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-fair-launch-rule-table-row'}>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>-</div>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>*/}
                    {/*                        <div className={'z-fair-launch-rule'}>*/}
                    {/*                            Join NFT loyal player white list after game release.*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-fair-launch-rule-table-row'}>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>-</div>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>*/}
                    {/*                        <div className={'z-fair-launch-rule'}>*/}
                    {/*                            Higher chance to gain NFT in game.*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-fair-launch-rule-table-row'}>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>-</div>*/}
                    {/*                    <div className={'zm-fair-launch-rule-table-cell'}>*/}
                    {/*                        <div className={'z-fair-launch-rule'}>*/}
                    {/*                            Qualified to create a guild in zworld.*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*        <div className={'zm-swap-container'}>*/}
                    {/*            <div*/}
                    {/*                className={'zm-swap-container-mask' + (this.state.connectedAccount ? ' hide' : '')}>*/}
                    {/*                <div className={'zm-swap-container-mask-hint'}>*/}
                    {/*                    <div className={'zm-swap-container-mask-hint-text'}>Connect your wallet to*/}
                    {/*                        swap.*/}
                    {/*                    </div>*/}
                    {/*                    <div className={'zm-swap-container-connect-btn disable-select'}*/}
                    {/*                         onClick={this.onConnectToWallet.bind(this)}>Connect to Wallet*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-swap-container-mask' + (this.state.showSwapLoading ? '' : ' hide')}>*/}
                    {/*                <div className={'z-swap-loading-container'}>*/}
                    {/*                    <div className="lds-ripple">*/}
                    {/*                        <div></div>*/}
                    {/*                        <div></div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className={'zm-swap-loading-text'}>Loading...</div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-swap-title-container'}>*/}
                    {/*                <div className={'z-swap-title'}>Swap for $ZWORLD and get reward</div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'z-swap-coin-container'}>*/}
                    {/*                <div className={'z-swap-seller-container'}>*/}
                    {/*                    <div className={'z-swap-sell-coin-title-container'}>*/}
                    {/*                        <div className={'z-swap-sell-coin-title'}>*/}
                    {/*                            USDT*/}
                    {/*                        </div>*/}
                    {/*                        <div*/}
                    {/*                            className={'z-swap-sell-coin-balance'}>Balance: {this.state.usdtBalanceDisplay}</div>*/}
                    {/*                    </div>*/}

                    {/*                    <input type="number" className={'zm-swap-amount-input'}*/}
                    {/*                           placeholder={'Type in amount to swap.'}*/}
                    {/*                           value={this.state.swapInputDisplay}*/}
                    {/*                           onChange={async (event) => {*/}
                    {/*                               let value = event.target.value*/}
                    {/*                               if (!value) {*/}
                    {/*                                   this.setState({*/}
                    {/*                                       swapInputDisplay: '',*/}
                    {/*                                       swapInputValue: 0,*/}
                    {/*                                       swapOutputValue: 0,*/}
                    {/*                                       swapOutputDisplay: ''*/}
                    {/*                                   })*/}
                    {/*                                   return*/}
                    {/*                               }*/}
                    {/*                               if (value === '0' || value === '0.') {*/}
                    {/*                                   this.setState({*/}
                    {/*                                       swapInputDisplay: value,*/}
                    {/*                                       swapInputValue: 0,*/}
                    {/*                                       swapOutputValue: 0,*/}
                    {/*                                       swapOutputDisplay: value*/}
                    {/*                                   })*/}
                    {/*                                   return*/}
                    {/*                               }*/}
                    {/*                               let inputAmount = Math.ceil(value * 100)*/}
                    {/*                               let fullAmount = this.getFullAmount(inputAmount, 16)*/}
                    {/*                               if (new BN(fullAmount).cmp(new BN(this.state.usdtBalance)) === 1) {*/}
                    {/*                                   fullAmount = this.state.usdtBalance*/}
                    {/*                                   value = this.state.usdtBalanceDisplay*/}
                    {/*                               }*/}
                    {/*                               const outputAmount = await this.getAmountOut(fullAmount)*/}
                    {/*                               const outputAmountDisplay = this.convertToWhole(outputAmount, 18)*/}
                    {/*                               this.setState({*/}
                    {/*                                   swapOutputDisplay: outputAmountDisplay,*/}
                    {/*                                   swapOutputValue: outputAmount,*/}
                    {/*                                   swapInputValue: fullAmount,*/}
                    {/*                                   swapInputDisplay: value*/}
                    {/*                               })*/}

                    {/*                               this.updatePrice()*/}
                    {/*                           }}/>*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-swap-arrow-container'}>*/}
                    {/*                    <img src={arrowDown} alt="arrow down" className={'z-swap-transaction-arrow'}/>*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-swap-seller-container'}>*/}
                    {/*                    <div className={'z-swap-sell-coin-title-container'}>*/}
                    {/*                        <div className={'zm-swap-sell-coin-title'}>$ZWORLD*/}
                    {/*                            <div className={'zm-swap-add-coin-to-wallet-btn'}*/}
                    {/*                                 onClick={this.onAddCoinToWallet.bind(this)}>*/}
                    {/*                                <img className={'zm-swap-add-coin-to-wallet-img'}*/}
                    {/*                                     src={addCoinToWalletIcon} alt="add coin to wallet"/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div*/}
                    {/*                            className={'z-swap-sell-coin-balance'}>Balance: {this.state.zwtBalanceDisplay}</div>*/}
                    {/*                    </div>*/}
                    {/*                    <input type="number" className={'zm-swap-amount-input'}*/}
                    {/*                           placeholder={'Type in amount to receive.'}*/}
                    {/*                           value={this.state.swapOutputDisplay}*/}
                    {/*                           onChange={async (event) => {*/}
                    {/*                               const value = event.target.value*/}
                    {/*                               if (!value) {*/}
                    {/*                                   this.setState({*/}
                    {/*                                       swapInputDisplay: '',*/}
                    {/*                                       swapInputValue: 0,*/}
                    {/*                                       swapOutputValue: 0,*/}
                    {/*                                       swapOutputDisplay: ''*/}
                    {/*                                   })*/}
                    {/*                                   return*/}
                    {/*                               }*/}
                    {/*                               let outputAmount = Math.ceil(value * 100)*/}
                    {/*                               let fullAmount = this.getFullAmount(outputAmount, 16)*/}
                    {/*                               //let fullAmount = this.getFullAmount(inputAmount, 16)*/}

                    {/*                               let inputAmount = await this.getAmountIn(fullAmount)*/}
                    {/*                               // inputAmount = inputAmount[0]*/}
                    {/*                               let inputAmountDisplay = this.convertToWhole(inputAmount, 18)*/}
                    {/*                               if (new BN(inputAmount).cmp(new BN(this.state.usdtBalance)) === 1) {*/}
                    {/*                                   inputAmount = this.state.usdtBalance*/}
                    {/*                                   inputAmountDisplay = this.convertToWhole(inputAmount, 18)*/}
                    {/*                                   outputAmount = await this.getAmountOut(inputAmount)*/}
                    {/*                                   const outputAmountDisplay = this.convertToWhole(outputAmount, 18)*/}
                    {/*                                   this.setState({*/}
                    {/*                                       swapInputDisplay: inputAmountDisplay,*/}
                    {/*                                       swapInputValue: inputAmount,*/}
                    {/*                                       swapOutputValue: outputAmount,*/}
                    {/*                                       swapOutputDisplay: outputAmountDisplay*/}
                    {/*                                   })*/}
                    {/*                                   return*/}
                    {/*                               }*/}
                    {/*                               this.setState({*/}
                    {/*                                   swapInputDisplay: inputAmountDisplay,*/}
                    {/*                                   swapInputValue: inputAmount,*/}
                    {/*                                   swapOutputValue: fullAmount,*/}
                    {/*                                   swapOutputDisplay: value*/}
                    {/*                               })*/}
                    {/*                               this.updatePrice()*/}
                    {/*                           }}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            {*/}
                    {/*                (this.state.swapOutputValue && this.state.swapInputValue) ?*/}
                    {/*                    <div className={'z-swap-price-container'}>*/}
                    {/*                        Price: {this.state.priceValueDisplay} $ZWORLD per USDT*/}
                    {/*                    </div> : <div/>*/}

                    {/*            }*/}
                    {/*            <div className={'z-swap-btn-container'}>*/}
                    {/*                <div*/}
                    {/*                    className={'zm-swap-btn disable-select' + (this.state.swapInputValue > 0 ? '' : ' z-btn-disabled')}*/}
                    {/*                    onClick={this.onSwap.bind(this)}>Swap*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}

                    {/*<div className={'zm-hr'}/>*/}
                    {/*<section id={'v-armory-section'} className={"zm-armory-section"}>*/}
                    {/*    <img className={'z-nft-section-bg'} src={bgGrunge} alt=""/>*/}
                    {/*    <div className={'z-armory-container'}>*/}
                    {/*        <div className={'z-section-title'}>Your Armory</div>*/}
                    {/*        {*/}
                    {/*            this.getArmoryEle()*/}
                    {/*        }*/}

                    {/*    </div>*/}
                    {/*    <div className={'z-armory-weapon-case-intro-container'}>*/}

                    {/*        <div className={'z-armory-weapon-case-title'}>Blind Box Type:</div>*/}
                    {/*        <div className={'zm-armory-weapon-case-container'}>*/}
                    {/*            <div className={'zm-armory-weapon-case-container-row'}>*/}
                    {/*                <div className={'z-weapon-case-img-container'}>*/}
                    {/*                    <img className={'z-weapon-case-img'} src={normalWeaponCase}*/}
                    {/*                         alt="normal weapon case"/>*/}
                    {/*                    <div className={'z-normal-weapon-case-label'}>Normal Weapon NFT Blind Box</div>*/}
                    {/*                </div>*/}

                    {/*                <div className={'zm-armory-arrow-cell'}>*/}
                    {/*                    <img className={'zm-armory-arrow-img'} src={normalArrow} alt="arrow"/>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-armory-price-container'}>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-normal-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={normalCaseOpen1}*/}
                    {/*                                     alt="normal case open 1"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-normal-prize-label'}>SMG NFT Package</div>*/}
                    {/*                            <div className={'zm-armory-normal-prize-value-label'}>$10 value</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}

                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-normal-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={normalCaseOpen2}*/}
                    {/*                                     alt="normal case open 2"/>*/}
                    {/*                            </div>*/}

                    {/*                            <div className={'zm-armory-normal-prize-label'}>Sniper NFT Package</div>*/}
                    {/*                            <div className={'zm-armory-normal-prize-value-label'}>$20 value</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-normal-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={normalCaseOpen3}*/}
                    {/*                                     alt="normal case open 3"/>*/}
                    {/*                            </div>*/}

                    {/*                            <div className={'zm-armory-normal-prize-label'}>*/}
                    {/*                                AR NFT Package*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-normal-prize-value-label'}>$40 value</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'zm-armory-weapon-case-container-row'}>*/}
                    {/*                <div className={'z-weapon-case-img-container'}>*/}
                    {/*                    <img className={'z-weapon-case-img'} src={eliteWeaponCase}*/}
                    {/*                         alt="elite weapon case"/>*/}
                    {/*                    <div className={'z-elite-weapon-case-label'}>Elite Weapon NFT Blind Box</div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-armory-arrow-cell'}>*/}
                    {/*                    <img className={'zm-armory-arrow-img'} src={eliteArrow} alt="arrow"/>*/}
                    {/*                </div>*/}

                    {/*                <div className={'zm-armory-price-container'}>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-elite-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={eliteCaseOpen1}*/}
                    {/*                                     alt="elite case open 1"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-elite-prize-label'}>Heavy Sniper NFT Package*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-elite-prize-value-label'}>$40 value</div>*/}
                    {/*                        </div>*/}

                    {/*                    </div>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}

                    {/*                            <div className={'z-armory-elite-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={eliteCaseOpen2}*/}
                    {/*                                     alt="elite case open 2"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-elite-prize-label'}>Shotgun NFT Package</div>*/}
                    {/*                            <div className={'zm-armory-elite-prize-value-label'}>$50 value</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-elite-prize-img-container'}>*/}

                    {/*                                <img className={'z-armory-prize-img'} src={eliteCaseOpen3}*/}
                    {/*                                     alt="elite case open 2"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-elite-prize-label'}>Multi AR NFT Package*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-elite-prize-value-label'}>$70 value</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'zm-armory-weapon-case-container-row'}>*/}
                    {/*                <div className={'z-weapon-case-img-container'}>*/}
                    {/*                    <img className={'z-weapon-case-img'} src={legendaryWeaponCase}*/}
                    {/*                         alt="legendary weapon case"/>*/}
                    {/*                    <div className={'z-legendary-weapon-case-label'}>Legendary Weapon NFT Blind*/}
                    {/*                        Box*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-armory-arrow-cell'}>*/}
                    {/*                    <img className={'zm-armory-arrow-img'} src={legendaryArrow} alt="arrow"/>*/}
                    {/*                </div>*/}
                    {/*                <div className={'zm-armory-price-container'}>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-legendary-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={legendaryCaseOpen1}*/}
                    {/*                                     alt="legendary case open 1"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-legendary-prize-label'}>*/}
                    {/*                                Rocket Launcher NFT Package*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-legendary-prize-value-label'}>$70 value</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-legendary-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={legendaryCaseOpen2}*/}
                    {/*                                     alt="legendary case open 2"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-legendary-prize-label'}>*/}
                    {/*                                Heavy Combat AR NFT Package*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-legendary-prize-value-label'}>$80 value</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className={'zm-armory-prize-cell'}>*/}
                    {/*                        <div className={'z-armory-prize-container'}>*/}
                    {/*                            <div className={'z-armory-legendary-prize-img-container'}>*/}
                    {/*                                <img className={'z-armory-prize-img'} src={legendaryCaseOpen3}*/}
                    {/*                                     alt="legendary case open 3"/>*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-legendary-prize-label'}>Heavy Sniper NFT*/}
                    {/*                                Package*/}
                    {/*                            </div>*/}
                    {/*                            <div className={'zm-armory-legendary-prize-value-label'}>$100 value*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={'zm-armory-weapon-case-des'}>*/}
                    {/*            <img className={'zm-nft-important'}*/}
                    {/*                                                          src={importantIcon} alt="important"/>All*/}
                    {/*            NFTs can be traded in Zworld or Opensea NFT marketplace.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    <div className={'zm-hr'}/>
                    <section id={'zm-nft-section'} className={"zm-nft-section"}>
                        <img className={'zm-nft-section-bg'} src={bgGrunge} alt=""/>
                        <img className={'zm-nft-section-hand-img'} src={bgHand} alt=""/>
                        <img className={'zm-nft-section-scratch-img'} src={bloodScratch} alt=""/>
                        <div className={'zm-nft-weapon-section-container'}>
                            <div className={'zm-section-title'}>Over 10,000 NTF</div>
                            <div className={'zm-section-nft-sub-title'}>
                                <div className={'zm-section-nft-sub-title-row'}>
                                    <div
                                        className={'zm-section-nft-sub-title-cell zm-section-nft-sub-title-cell-bullet'}>-
                                    </div>
                                    <div className={'zm-section-nft-sub-title-cell'}>NFT weapon can be used in game,
                                        better weapon
                                        better game experience and earn more $ZWORLD for each kill.
                                    </div>
                                </div>
                                <div className={'zm-section-nft-sub-title-row'}>
                                    <div
                                        className={'zm-section-nft-sub-title-cell zm-section-nft-sub-title-cell-bullet'}>-
                                    </div>
                                    <div className={'zm-section-nft-sub-title-cell'}>NFT weapons are upgradable in game,
                                        player can use $ZWORLD to enhance weapon NFT from
                                        fire power, capacity, accuracy and durability aspects.
                                    </div>
                                </div>
                                <div className={'zm-section-nft-sub-title-row'}>
                                    <div
                                        className={'zm-section-nft-sub-title-cell zm-section-nft-sub-title-cell-bullet'}>-
                                    </div>
                                    <div className={'zm-section-nft-sub-title-cell'}>Weapon NFT can be traded in ZWorld
                                        marketplace.
                                    </div>
                                </div>
                            </div>
                            <div className={'zm-nft-card-container'}>
                                <img className={'zm-nft-img'} src={nftCardsMobile} alt=""/>
                            </div>
                        </div>
                    </section>
                    <div className={'zm-hr'}/>
                    <section className={'zm-two zm-game-system-section'}>
                        <div className={'zm-section-container'}>
                            <div className={'zm-section-title'}>Kill to Earn System</div>
                            {/*<div className={'z-section-sub-title'}>Defend yourself against hordes of zombies using*/}
                            {/*    special weapon NFTs. Earn $ZWT for each kill.*/}
                            {/*</div>*/}
                            <div className={'z-section-sub-title'}>Defend yourself against hordes of zombies using
                                special weapon. Earn for each kill.
                            </div>
                            <div className={'z-section-gameview-container'}>
                                <div className={'z-gameview-wrapper'}>

                                    {/*<img src={desktopScreen} alt="desktop screen" className={'z-desktop-screen-img'}/>*/}
                                </div>
                            </div>

                            {/*<VideoPlayer muted {...videoJsGamePlayOptions} videoClassName={'z-gameplay-video-container'}/>*/}
                        </div>
                        <div className={'zm-gameplay-video-wrapper'}>
                            <div dangerouslySetInnerHTML={{
                                __html: `
                            <video
                              id='vm-gameplay-video'
                              loop
                              muted
                              autoPlay
                              autoplay="autoplay"
                              playsinline
                              src="https://zassets.nebulabinarycloud.cc/gameplay_demo.mp4"
                              class="zm-gameplay-video"
                            />,
                          `
                            }}></div>

                        </div>

                        <img className={'zm-gameplay-img'} src={zombieGamePlay} alt="zombie game play"/>
                    </section>
                    <div className={'zm-hr'}/>
                    <section className={'three zm-weapon-container'}>
                        {/*<img className={'z-weapon-mask'} src={weaponMask} alt="weapon mask"/>*/}
                        <div className={'zm-weapon-section-container'}>
                            <div className={'zm-section-title'}>
                                {/*Collect Material, Forge and Upgrade Your Weapons NFT and More*/}
                                Collect Material, Forge and Upgrade Your Weapons and More
                            </div>
                            <div className={'zm-section-sub-title'}/>
                            <div className={'zm-weapon-section-content-container'}>
                                <div className={'zm-weapon-desk-wrapper'}>
                                    <img className={'zm-weapon-desk-img'} src={weaponDesk} alt="weapon desk"/>
                                </div>
                                <div className={'zm-weapon-upgrade-video-container'}>
                                    <div className={'zm-weapon-upgrade-video-wrapper'}>

                                        {/*<div dangerouslySetInnerHTML={{*/}
                                        {/*    __html: `*/}
                                        {/*    <video*/}
                                        {/*      id='vm-weapon-upgrade-video'*/}
                                        {/*      loop*/}
                                        {/*      muted*/}
                                        {/*      autoPlay*/}
                                        {/*      autoplay="autoplay"*/}
                                        {/*      playsinline*/}
                                        {/*      src="video/weapon_upgrade.mp4"*/}
                                        {/*      class="zm-gameplay-video"*/}
                                        {/*    />,*/}
                                        {/*`*/}
                                        {/*}}></div>*/}
                                        <video id={'vm-weapon-upgrade-video'} loop autoPlay muted playsinline
                                               src="https://zassets.nebulabinarycloud.cc/weapon_upgrade.mp4"
                                               className={'zm-gameplay-video'}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <div className={'zm-hr'}/>
                    <section id={'zm-tokenomic-section'} className={'zm-four zm-token-section'}>
                        <div className={'zm-section-mask'}/>
                        <img className={'zm-section-bg'} src={bgCar} alt="bg car"/>
                        <div className={'zm-section-header'}>ZWORLD Tokenomic</div>
                        <div className={'zm-section-sub-header'}>ZWorld's Battleground will be governed and supported by
                            our native & fungible $ZWORLD Tokens.
                            Below is the token allocation structure. All lock-up & vesting periods are detailed to
                            ensure benefits for all token holders.
                            Our token comes with various utilities for both DeFi functions & NFT Transactions.
                        </div>
                        <div className={'zm-token-chart-container'}>
                            <div className={'zm-token-chart-items'}>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 0 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 0)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'zm-token-chart-item-title'}>Ecosystem & Reward in Games</div>
                                    <div className={'zm-token-chart-item-percentage'}>40%</div>
                                    <div className={'zm-token-chart-item-token-amt'}>(92,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 1 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 1)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'zm-token-chart-item-title'}>NFT Public Sale</div>
                                    <div className={'zm-token-chart-item-percentage'}>31%</div>
                                    <div className={'zm-token-chart-item-token-amt'}>(3,720,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 2 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 2)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'zm-token-chart-item-title'}>Team & Operation</div>
                                    <div className={'zm-token-chart-item-percentage'}>14%</div>
                                    <div className={'zm-token-chart-item-token-amt'}>(1,680,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 3 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 3)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'zm-token-chart-item-title'}>Marketing</div>
                                    <div className={'zm-token-chart-item-percentage'}>7%</div>
                                    <div className={'zm-token-chart-item-token-amt'}>(840,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 4 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 4)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'zm-token-chart-item-title'}>Liquidity</div>
                                    <div className={'zm-token-chart-item-percentage'}>5%</div>
                                    <div className={'zm-token-chart-item-token-amt'}>(600,000,000 tokens)</div>
                                </div>
                                <div
                                    className={'z-token-chart-item-container ' + (this.state.doughnutHoverIndex === 5 ? 'z-hover' : '')}
                                    onMouseOver={this.onDistributionHover.bind(this, 5)}
                                    onMouseLeave={this.resetDoughnutHighlight.bind(this)}>
                                    <div className={'zm-token-chart-item-title'}>Black Hole</div>
                                    <div className={'zm-token-chart-item-percentage'}>3%</div>
                                    <div className={'zm-token-chart-item-token-amt'}>(360,000,000 tokens)</div>
                                </div>
                            </div>
                            <div className={'z-token-chart-wrapper'}>
                                <Doughnut data={this.state.chartData} options={this.options}/>
                            </div>
                            <div className={'zm-token-chart-overview'}>
                                <div className={'zm-token-chart-overview-card'}>
                                    <div className={'zm-token-chart-overview-card-title'}>
                                        Token Name
                                    </div>
                                    <div className={'zm-token-chart-overview-card-content'}>
                                        ZWORLD
                                    </div>
                                </div>
                                <div className={'zm-token-chart-overview-card'}>
                                    <div className={'zm-token-chart-overview-card-title'}>
                                        Platform
                                    </div>
                                    <div className={'zm-token-chart-overview-card-content'}>
                                        Binance Smart Chain
                                    </div>
                                </div>
                                <div className={'zm-token-chart-overview-card-total-supply'}>
                                    <div className={'zm-token-chart-overview-card-title'}>
                                        Total Supply
                                    </div>
                                    <div className={'zm-token-chart-overview-card-content'}>
                                        12,000,000,000 tokens
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className={'zm-hr'}/>
                    <section id={'zm-roadmap-section'} className={'zm-four z-roadmap-section-container'}>
                        <div className={'zm-section-roadmap-mask'}/>
                        <img className={'zm-roadmap-section-bg'} src={roadmapBg} alt=""/>
                        <div className={'zm-section-header'}>Roadmap</div>
                        <div className={'zm-section-sub-header'}>Stay ahead of the race and lead the Game market with
                            upcoming advanced functions.
                        </div>
                        <div className={'zm-roadmap-container'}>
                            <div className={'zm-roadmap-row'}>
                                <div className={'zm-roadmap-block zm-roadmap-passed'}>
                                    {/*<div className={'z-roadmap-mask'}/>*/}
                                    <div className={'z-roadmap-title'}>Q2 {getCurrentYear()}</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Financial plan</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Tokenomics design</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Private sales round</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'zm-roadmap-block zm-roadmap-passed'}>
                                    <div className={'z-roadmap-title'}>Q3 {getCurrentYear()}</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Game development</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Blockchain development</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>IDO</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>INO</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>UI/UX and Animation improvement</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Map Integration</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Guild</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'zm-roadmap-block'}>
                                    <div className={'z-roadmap-title'}>Q4 {getCurrentYear()}</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={finishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Fair launch event</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Auditing</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Release game version 1.0 for PC, Android</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Releasing game version 2.0</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Leaderboard</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>PVP game mode</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Free Battle Round for Ad viewer</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'zm-roadmap-block'}>
                                    <div className={'z-roadmap-title'}>Q2 2023</div>
                                    <div className={'z-timeline'}>
                                        <img className={'z-timeline-dot'} src={timelineDot} alt="timeline dot"/>
                                    </div>
                                    <div className={'z-timeline-content-container'}>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Releasing iOS App</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Yield Farming/Staking optimization</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Tournament</div>
                                        </div>
                                        <div className={'z-timeline-item'}>
                                            <img className={'z-timeline-check-icon'} src={unfinishedCheck}
                                                 alt="unfinished check"/>
                                            <div>Ecosystem pushing</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*<div className={'z-hr'}/>*/}
                    {/*<section id={'zm-team-section'} className={'zm-four zm-member-section-container'}>*/}
                    {/*    <img className={'zm-partner-section-bg'} src={bgGrunge} alt=""/>*/}
                    {/*    <div className={'zm-section-header'}>Core Team Members</div>*/}
                    {/*    <div className={'zm-section-sub-header'}>The team always plays an important role in the success*/}
                    {/*        of a project.*/}
                    {/*        Credentialed team with experience from leading organisations*/}
                    {/*    </div>*/}
                    {/*    <div className={'zm-members-container'}>*/}
                    {/*        <div className={'zm-members-row'}>*/}
                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member1} alt={'member 1'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    George Harris*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    CEO & CTO*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    Master of Software Developer, 19 years of experience in*/}
                    {/*                    software development*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member2} alt={'member2'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Amar Hansada*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    COO*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    10 years of game design. 5 years of marketing*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={'zm-members-row'}>*/}
                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member3} alt={'member3'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Luis Chen*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    CO-FOUNDER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    8+ years of experience in 3D game artist 3D Game Artist*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member4} alt={'member4'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Harrison Anderson*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    ARTIST LEADER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    Artist leader 10+ years of experience in 2D & 3D Design*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={'zm-members-row'}>*/}

                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member5} alt={'member5'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Kevin Liu*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    WEB DEVELOPER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    4 years of good experiences in web backend & frontend development*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member6} alt={'member6'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Anthony Baker*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    GRAPHIC DESIGNER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    3 years of experiences in Ui/Ux design*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={'zm-members-row'}>*/}
                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member7} alt={'member7'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Alexis Howard*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    BLOCKCHAIN DEVELOPER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    5 years of experience in blockchain development*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'zm-member-card'}>*/}
                    {/*                <img className={'z-member-img'} src={member8} alt={'member8'}/>*/}
                    {/*                <div className={'z-member-name'}>*/}
                    {/*                    Ray Wood*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-title'}>*/}
                    {/*                    MARKETING MANAGER*/}
                    {/*                </div>*/}
                    {/*                <div className={'z-member-exp'}>*/}
                    {/*                    Digital Marketing Specialist with 10+ years of experience. Skilled in SEO/SEM,*/}
                    {/*                    Digital Marketing and Online Marketing*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    <div className={'z-hr'}/>
                    <section className={'zm-four zm-partner-section-container'}>
                        <img className={'zm-partner-section-bg'} src={bgGrunge} alt=""/>
                        <div className={'zm-section-header'}>Partners</div>
                        <div className={'zm-partners-container'}>
                            <div className={'zm-partners-row'}>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partner1} alt={'partner1'}/>
                                </div>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partner2} alt={'partner2'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'zm-partners-container'}>
                            <div className={'zm-partners-row'}>
                                <div className={'zm-partner-card'}>>
                                    <img className={'z-partner-img'} src={partner3} alt={'partner3'}/>
                                </div>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partner4} alt={'partner4'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'zm-partners-container'}>
                            <div className={'zm-partners-row'}>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partner5} alt={'partner5'}/>
                                </div>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partner6} alt={'partner6'}/>
                                </div>
                            </div>
                        </div>


                        <div className={'zm-partners-container'}>
                            <div className={'zm-partners-row'}>

                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partner7} alt={'partner7'}/>
                                </div>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partner8} alt={'partner8'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'zm-partners-container'}>
                            <div className={'zm-partners-row'}>

                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerGateIo} alt={'partner'}/>
                                </div>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerIbcig} alt={'partner'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'zm-partners-container'}>
                            <div className={'zm-partners-row'}>

                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerCoinlab} alt={'partner'}/>
                                </div>
                                <div className={'zm-partner-card'}>
                                    <img className={'z-partner-img'} src={partnerAlien} alt={'partner'}/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className={'z-hr'}/>
                    <section className={'zm-four zm-audit-section-container'}>
                        <div className={'zm-section-header'}>Audited By</div>
                        <div>
                            <img className={'zm-audition-img'} src={audition} alt="audition"/>
                        </div>
                    </section>

                    {/*<div className={'z-hr'}/>*/}
                    {/*<section className={'four z-audit-section-container'}>*/}
                    {/*    <div className={'z-section-header'}>Contact Us</div>*/}
                    {/*    <div className={'z-contact-info-container'}>*/}
                    {/*        <div className={'z-section-contact-text'}>*/}
                    {/*            <div className={'z-section-contact-row'}>Company Name: <br/>ArgoCloud Inc.</div>*/}
                    {/*            <div className={'z-section-contact-row'}>Email: <br/>contact@zworldmeta.org</div>*/}
                    {/*            <div className={'z-section-contact-row'}>Phone: <br/>+1 562 580 7972</div>*/}
                    {/*            <div className={'z-section-contact-row'}>Address: <br/>543 Fillmore St, Denver, CO, USA 80206</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    <section className={'zm-footer'}>
                        <img className={'zm-footer-bg-mask'} src={footerBg}/>
                        <div className={'zm-footer-content-wrapper'}>
                            <img className={'zm-footer-logo'} src={webLogoPng} alt="logo"/>
                            {/*<div className={'zm-footer-text'}>Audit Report</div>*/}
                            <div className={'zm-social-icons-container'}>
                                {/*<div className={'zm-media-container'}><img className={'z-media-icon'} src={facebook}*/}
                                {/*                                           alt="facebook"/></div>*/}
                                <div className={'zm-media-container'} onClick={this.onTelegramClicked.bind(this)}><img
                                    className={'z-media-icon'} src={telegram}
                                    alt="facebook"/></div>
                                <div className={'zm-media-container'} onClick={this.onTwitterClicked.bind(this)}><img className={'z-media-icon'} src={twitter}
                                                                           alt="facebook"/></div>
                                {/*<div className={'zm-media-container'}><img className={'z-media-icon'} src={youtube}*/}
                                {/*                                           alt="facebook"/></div>*/}

                            </div>
                            <div className={'zm-footer-copyright'}>Copyright © {getCurrentYear()} Nebula Binary Software Inc.</div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Mobile;
